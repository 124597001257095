import React from 'react';
import PropTypes from 'prop-types';

import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Bar = styled('div')(({ theme, width, r, g, b }) => ({
  height: 25,
  width: `${width}%`,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: 4,
  backgroundImage: `linear-gradient(to bottom, rgba(${r}, ${g}, ${b}, 1), rgba(${r}, ${g}, ${b}, .8))`,
  boxShadow: theme.shadows[4],
}));

function SingleBar(props) {
  const { value, max, color } = props;
  const fillWidthPercent = max !== 0 ? (value / max) * 100 : 0;

  let _color = color.replace('rgba(', '').replace(')', '');
  const [r, g, b] = _color.split(',');
  return (
    <Box>
      <Bar width={fillWidthPercent} r={r} g={g} b={b}>
        <Typography
          variant='h6'
          align='left'
          color='textSecondary'
          sx={{ ml: 1 }}>
          {value}
        </Typography>
      </Bar>
    </Box>
  );
}

SingleBar.propTypes = {
  meter: PropTypes.object,
  height: PropTypes.number,
};

export default SingleBar;
