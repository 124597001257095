import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';

import {
  StyledSubtab,
  StyledSubtabs,
} from '../../../../components/StyledSubtabs';
import ReportTools from './ReportTools';
import LicenseTools from './LicenseTools';
import UtilityAccountTools from './UtilityAccountTools';
import PlugTools from './PlugTools';

const REPORT = 'Report Tools';
const LICENSE = 'License Tools';
const ACCOUNT = 'Utility Account Tools';
const PLUG = 'Plug Tools';
const SUBTABS = [REPORT, LICENSE, ACCOUNT, PLUG];

export default function Tools() {
  const [tab, setTab] = useState(REPORT);

  const handleSelect = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Grid
      container
      justifyContent='center'
      spacing={1}
      sx={{ maxWidth: '100%', mt: 1 }}>
      <Grid
        size={{ xs: 12 }}
        sx={{ display: 'flex', justifyContent: 'center', mx: 1 }}>
        <StyledSubtabs
          variant='fullWidth'
          sx={{ maxWidth: 400, width: '100%' }}
          value={tab}
          onChange={handleSelect}>
          {SUBTABS.map((tab) => (
            <StyledSubtab key={tab} label={tab} value={tab} />
          ))}
        </StyledSubtabs>
      </Grid>
      {tab === REPORT && <ReportTools />}
      {tab === LICENSE && <LicenseTools />}
      {tab === ACCOUNT && <UtilityAccountTools />}
      {tab === PLUG && <PlugTools />}
    </Grid>
  );
}
