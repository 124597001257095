import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { SOLAR_APP_ID } from '.';
import { ROUTES } from '../../helpers/constants';
import { toastr } from '../../components/CustomToast';

const postSolarSensor = createAsyncThunk(
  'solar/postSolarSensor',
  async (newSensor, { dispatch, getState }) => {
    const stateSensors = getState().solar.sensors;
    let sensors = cloneDeep(stateSensors);
    try {
      dispatch(showLoading());
      const sensor = await new WebAPIClient(SOLAR_APP_ID).POST(
        '/resource/sensors',
        newSensor
      );

      toastr.success({ title: 'Sensor created' });
      return {
        sensors: [...sensors, sensor],
        newSensor: sensor,
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putSolarSensor = createAsyncThunk(
  'solar/putSolarSensor',
  async (updatedSensor, { dispatch, getState }) => {
    const stateSensors = getState().solar.sensors;
    let sensors = cloneDeep(stateSensors);
    try {
      dispatch(showLoading());

      const sensor = await new WebAPIClient(SOLAR_APP_ID).PUT(
        `/resource/sensors/${updatedSensor.org_id}/${updatedSensor.sensor_id}`,
        updatedSensor
      );
      remove(sensors, {
        org_id: updatedSensor.org_id,
        sensor_id: updatedSensor.sensor_id,
      });
      sensors = concat(sensors, sensor);

      toastr.success({ title: 'Sensor updated' });
      return { sensors };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteSolarSensor = createAsyncThunk(
  'solar/deleteSolarSensor',
  async (deletedSensor, { dispatch, getState }) => {
    const stateSensors = getState().solar.sensors;
    let sensors = cloneDeep(stateSensors);
    try {
      dispatch(showLoading());
      await new WebAPIClient(SOLAR_APP_ID).DELETE(
        `/resource/sensors/${deletedSensor.org_id}/${deletedSensor.sensor_id}`
      );

      remove(sensors, {
        org_id: deletedSensor.org_id,
        sensor_id: deletedSensor.sensor_id,
      });

      toastr.success({ title: 'Sensor deleted' });
      return { sensors };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
      dispatch(push(ROUTES.LIGHTLEVEL.path));
    }
  }
);

export { postSolarSensor, putSolarSensor, deleteSolarSensor };
