import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { lastMonth } from '../../helpers/date';

function SelectRange(props) {
  const {
    ranges,
    range,
    defaultRange,
    setRange,
    timezone,
    disableFuture = true,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const [customRange, setCustomRange] = useState(
    find(ranges, { label: 'Custom Range' })
  );

  useEffect(() => {
    if (!range) {
      setRange({ ...defaultRange, ...defaultRange.func(timezone.zone) });
    }
  }, [range, timezone.zone, defaultRange, setRange]);

  const handleClose = () => {
    setOpen(false);
  };

  const selectRange = (e) => {
    e.preventDefault();
    const newRange = find(ranges, { label: e.target.value });
    if (newRange.key === customRange.key) {
      setCustomRange((r) => {
        let start, end;
        if (r.start) {
          start = r.start;
          end = r.end;
        } else {
          let result = lastMonth(timezone.zone);
          start = result.start;
          end = result.end;
        }

        return { ...r, start, end };
      });
      setOpen(true);
    } else {
      setRange({
        ...newRange,
        ...newRange.func(timezone.zone),
      });
      handleClose();
    }
  };

  const handleSelectDay = (day, attr) => {
    if (attr === 'end') {
      day = day.endOf('day');
    }
    setCustomRange((r) => {
      return { ...r, [attr]: day };
    });
  };

  const handleSubmit = () => {
    setRange(customRange);
    handleClose();
  };

  let rangeLabel = get(range, 'label', '');
  if (range && range.key === customRange.key)
    rangeLabel = `${range.start.format('MMM D')} - ${dayjs(range.end).format(
      'MMM D'
    )}`;

  return (
    <>
      <FormControl variant='standard'>
        <InputLabel
          id='range-label'
          sx={{ textAlign: 'center', width: '8rem' }}>
          Range
        </InputLabel>
        <Select
          disableUnderline
          labelId='range-label'
          value={rangeLabel}
          renderValue={() => (
            <ListItemText
              sx={{ color: theme.palette.primary.main, my: 0 }}
              primary={rangeLabel}
              slotProps={{
                primary: { align: 'center' },
              }}
            />
          )}
          onChange={selectRange}
          IconComponent={() => null}
          inputProps={{
            sx: {
              padding: '0 !important',
              color: theme.palette.primary.main,
              ...theme.typography.body2,
            },
          }}
          MenuProps={{
            MenuListProps: { disablePadding: true },
            slotProps: {
              paper: { sx: { minWidth: 'unset !important' } },
            },
          }}
          sx={{
            minWidth: '5rem',
            backgroundColor: 'unset',
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
            },
          }}>
          {ranges.map((range) => {
            return (
              <MenuItem
                sx={{
                  color: theme.palette.primary.main,
                  ...theme.typography.body2,
                }}
                key={range.label}
                value={range.label}>
                {range.label}
              </MenuItem>
            );
          })}
          {range && range.key === 6 && (
            <MenuItem
              sx={{
                color: theme.palette.primary.main,
                ...theme.typography.body2,
              }}
              key={rangeLabel}
              value={rangeLabel}>
              {rangeLabel}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <Stack direction='column' spacing={2}>
            <DatePicker
              disableFuture={disableFuture}
              openTo='month'
              views={['year', 'month', 'day']}
              label='Start'
              value={customRange.start || dayjs()}
              onChange={(newValue) => {
                handleSelectDay(newValue, 'start');
              }}
              timezone={timezone.zone}
              slotProps={{
                textField: {
                  variant: 'standard',
                  inputProps: {
                    readOnly: true,
                  },
                },
              }}
            />
            <DatePicker
              disableFuture={disableFuture}
              openTo='month'
              views={['year', 'month', 'day']}
              label='End'
              value={customRange.end || dayjs()}
              minDate={customRange.start}
              onChange={(newValue) => {
                handleSelectDay(newValue, 'end');
              }}
              timezone={timezone.zone}
              slotProps={{
                textField: {
                  variant: 'standard',
                  inputProps: { readOnly: true },
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={
              customRange.start === undefined || customRange.end === undefined
            }>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

SelectRange.propTypes = {
  range: PropTypes.object,
  setRange: PropTypes.func,
  timezone: PropTypes.object,
};

export default SelectRange;
