import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import useTheme from '@mui/material/styles/useTheme';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function SelectMetrics(props) {
  const { metrics, selectedMetrics, setSelectedMetrics } = props;
  const theme = useTheme();

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedMetrics(e.target.value);
  };

  return (
    <Select
      displayEmpty
      multiple
      disableUnderline
      id='attribute-select'
      renderValue={() => (
        <ListItemText
          sx={{ color: theme.palette.primary.main, my: 0 }}
          primary='Metrics'
          slotProps={{
            primary: { align: 'center' },
          }}
        />
      )}
      value={selectedMetrics}
      variant='standard'
      onChange={handleSelect}
      sx={{ minWidth: '4rem' }}
      IconComponent={() => null}
      inputProps={{
        sx: {
          padding: '0 !important',
          color: theme.palette.primary.main,
          ...theme.typography.body2,
        },
      }}
      MenuProps={{
        MenuListProps: { disablePadding: true },
        slotProps: {
          paper: { sx: { minWidth: 'unset !important' } },
        },
      }}>
      {metrics.map((metric) => {
        return (
          <MenuItem key={metric} value={metric}>
            <Checkbox checked={selectedMetrics.indexOf(metric) > -1} />
            <ListItemText primary={startCase(metric)} />
          </MenuItem>
        );
      })}
    </Select>
  );
}

SelectMetrics.propTypes = {
  metrics: PropTypes.array.isRequired,
  selectedMetrics: PropTypes.array.isRequired,
  setSelectedMetrics: PropTypes.func,
};

export default SelectMetrics;
