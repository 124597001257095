import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { showLoading, hideLoading } from '../../../store/app';
import { toastr } from '../../CustomToast';
import BaseDialog from '../BaseDialog';
import SelectEnergyTracerRole from '../../Selectors/energytracer/SelectEnergyTracerRole';
import SelectEnergyTracerOrganization from '../../Selectors/energytracer/SelectEnergyTracerOrganization';
import WebAPIClient from '../../../api/_energytracer';

export const defaultState = {
  organization_id: '',
  portfolio_id: '',
  role: 'user',
};

function PortfolioOrganizationDialog(props) {
  const { portfolios, organizations, setTableRows, open, setOpen } = props;
  const dispatch = useDispatch();

  const [membership, setMembership] = useState(defaultState);

  const handleClose = () => {
    setOpen(false);
    setMembership({ ...defaultState });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    try {
      const response = await new WebAPIClient().PUT(
        `portfolios/portfolio_organizations/id/${membership.organization_id}/${membership.portfolio_id}/${membership.role}`
      );
      setTableRows((prev) => [
        {
          ...membership,
          portfolio_id: response.portfolio_id,
          organization_id: response.organization_id,
          portfolio_name: portfolios.find(
            (port) => port.id === membership.portfolio_id
          ).name,
          organization_name: organizations.find(
            (org) => org.id === membership.organization_id
          ).name,
        },
        ...prev,
      ]);
      dispatch(hideLoading());
      toastr.success({ title: 'Membership Updated' });
    } catch (err) {
      dispatch(hideLoading());
      toastr.error({ title: 'Request Failed' });
    }
    handleClose();
  };

  const id = open ? 'create-membership-dialog' : '';
  return (
    <BaseDialog
      id={id}
      title='Create Membership'
      submitText='Create'
      disabled={false}
      handleClose={handleClose}
      handleSubmit={handleSubmit}>
      <List disablePadding>
        <ListItem disableGutters>
          <SelectEnergyTracerOrganization
            label='Portfolio'
            portfolios={portfolios}
            orgId={membership.portfolio_id}
            setOrgId={(portfolioId) =>
              setMembership((prev) => {
                return { ...prev, portfolio_id: portfolioId };
              })
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <SelectEnergyTracerOrganization
            label='Organization'
            organizations={organizations}
            orgId={membership.organization_id}
            setOrgId={(orgId) =>
              setMembership((prev) => {
                return { ...prev, organization_id: orgId };
              })
            }
          />
        </ListItem>

        <ListItem disableGutters>
          <SelectEnergyTracerRole
            appId={'energytracer'}
            email={'email'}
            role={membership.role}
            setRole={(role) => setMembership({ ...membership, role: role })}
          />
        </ListItem>
      </List>
    </BaseDialog>
  );
}

export default PortfolioOrganizationDialog;
