import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Card from '@mui/material/Card';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import { SOLAR_APP_ID } from '../../store/solar';
import { DEMAND_APP_ID } from '../../store/demand';
import BaseTable from './BaseTable';
import HeaderEditCell from './cells/HeaderEditCell';
import EditCell from './cells/EditCell';
import WidgetLoader from '../Loaders/WidgetLoader';
import {
  OrganizationIdNameTypeProvider,
  UserStatusTypeProvider,
} from './providers';
import {
  default as SolarUserDialog,
  defaultState as defaultSolarUserState,
} from '../Dialogs/solar/UserDialog';
import {
  default as DemandUserDialog,
  defaultState as defaultDemandUserState,
} from '../Dialogs/demand/UserDialog';

function UsersTable(props) {
  const { appId } = props;
  const { loading, users, organizations } = useSelector(
    (state) => state[appId]
  );

  const defaultState = (() => {
    if (appId === SOLAR_APP_ID) {
      return { ...defaultSolarUserState };
    } else if (appId === DEMAND_APP_ID) {
      return { ...defaultDemandUserState };
    } else {
      return {};
    }
  })();

  const [mode, setMode] = useState(null);
  const [selectedUser, setSelectedUser] = useState(defaultState);

  const [columns] = useState([
    { title: 'Name', name: 'name', width: 0.2 },
    { title: 'Email', name: 'email', width: 0.275 },
    { title: 'Cognito Status', name: 'account_status', width: 0.2 },
    {
      title: 'Default Organization',
      name: 'default_organization',
      width: 0.275,
    },
  ]);

  return (
    <>
      <Card raised sx={{ maxWidth: 900, width: '100%', px: 1 }}>
        <WidgetLoader loading={loading} />
        <BaseTable
          minWidth={900}
          rows={users}
          columns={columns}
          editColumn={
            <TableEditColumn
              width={42}
              headerCellComponent={(props) => {
                return (
                  <HeaderEditCell
                    disabled={false}
                    tooltipText='Create User'
                    handleClick={() => {
                      setMode('create');
                      setSelectedUser({ ...defaultState });
                    }}
                    {...props}
                  />
                );
              }}
              cellComponent={(props) => (
                <EditCell
                  disabled={false}
                  tooltipText='Edit User'
                  icon={['fal', 'edit']}
                  // eslint-disable-next-line
                  handleClick={() => {
                    setMode('edit');
                    setSelectedUser(props.row);
                  }}
                  {...props}
                />
              )}
            />
          }>
          <UserStatusTypeProvider for={['account_status']} />
          <OrganizationIdNameTypeProvider
            for={['default_organization']}
            organizations={organizations}
          />
          <EditingState />
          <FilteringState />
          <SortingState defaultSorting={[]} />
          <PagingState defaultCurrentPage={0} pageSize={10} />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
        </BaseTable>
      </Card>
      {appId === SOLAR_APP_ID && (
        <SolarUserDialog
          mode={mode}
          setMode={setMode}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}
      {appId === DEMAND_APP_ID && (
        <DemandUserDialog
          mode={mode}
          setMode={setMode}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}
    </>
  );
}

export default UsersTable;
