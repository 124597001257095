import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { SOLAR_APP_ID } from '.';
import { ROUTES } from '../../helpers/constants';
import { toastr } from '../../components/CustomToast';

const postSolarInverter = createAsyncThunk(
  'solar/postSolarInverter',
  async (newInverter, { dispatch, getState }) => {
    const stateInverters = getState().solar.inverters;
    let inverters = cloneDeep(stateInverters);
    try {
      dispatch(showLoading());
      const inverter = await new WebAPIClient(SOLAR_APP_ID).POST(
        '/resource/inverters',
        newInverter
      );

      toastr.success({ title: 'Inverter created' });
      return {
        inverters: [...inverters, inverter],
        newInverter: inverter,
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putSolarInverter = createAsyncThunk(
  'solar/putSolarInverter',
  async (updatedInverter, { dispatch, getState }) => {
    const stateInverters = getState().solar.inverters;
    let inverters = cloneDeep(stateInverters);
    try {
      dispatch(showLoading());

      const inverter = await new WebAPIClient(SOLAR_APP_ID).PUT(
        `/resource/inverters/${updatedInverter.org_id}/${updatedInverter.inverter_id}`,
        updatedInverter
      );
      remove(inverters, {
        org_id: updatedInverter.org_id,
        inverter_id: updatedInverter.inverter_id,
      });
      inverters = concat(inverters, inverter);

      toastr.success({ title: 'Inverter updated' });
      return { inverters };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteSolarInverter = createAsyncThunk(
  'solar/deleteSolarInverter',
  async (deletedInverter, { dispatch, getState }) => {
    const stateInverters = getState().solar.inverters;
    let inverters = cloneDeep(stateInverters);
    try {
      dispatch(showLoading());
      await new WebAPIClient(SOLAR_APP_ID).DELETE(
        `/resource/inverters/${deletedInverter.org_id}/${deletedInverter.inverter_id}`
      );

      remove(inverters, {
        org_id: deletedInverter.org_id,
        inverter_id: deletedInverter.inverter_id,
      });

      toastr.success({ title: 'Inverter deleted' });
      return { inverters };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
      dispatch(push(ROUTES.LIGHTLEVEL.path));
    }
  }
);

export { postSolarInverter, putSolarInverter, deleteSolarInverter };
