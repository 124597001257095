import React from 'react';

import useTheme from '@mui/material/styles/useTheme';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import { MenuItemListItem, StyledMenuItem } from './CustomMenuItem';

export default function BaseSelect(props) {
  const { label, value, setValue, options, width = 'unset' } = props;
  const theme = useTheme();

  const handleSelect = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return (
    <FormControl variant='standard' sx={{ width }}>
      <InputLabel
        id={`${label}-select-label`}
        sx={{ textAlign: 'center', width: '8rem' }}>
        {label}
      </InputLabel>
      <Select
        displayEmpty
        disableUnderline
        id='value-select'
        labelId={`${label}-select-label`}
        renderValue={() => (
          <ListItemText
            sx={{ color: theme.palette.primary.main, my: 0 }}
            primary={value}
            slotProps={{
              primary: { align: 'center' },
            }}
          />
        )}
        value={value}
        variant='standard'
        onChange={handleSelect}
        sx={{ minWidth: '4rem' }}
        IconComponent={() => null}
        inputProps={{
          sx: {
            padding: '0 !important',
            color: theme.palette.primary.main,
          },
        }}
        MenuProps={{
          MenuListProps: { disablePadding: true },
        }}>
        {options.map((option) => {
          return (
            <StyledMenuItem key={option} value={option}>
              <MenuItemListItem value={option || '[none]'} />
            </StyledMenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
