import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import WebAPIClient, { errorResponseToastr } from '../../../../../api';
import { SOLAR_APP_ID } from '../../../../../store/solar';
import { openConfirmDialog } from '../../../../../store/dialogs';
import { toastr } from '../../../../../components/CustomToast';
import SelectDatetime from '../../../../../components/Selectors/SelectDatetime';
import WidgetLoader from '../../../../../components/Loaders/WidgetLoader';

export const METHOD = {
  name: 'Eclypse',
  description: 'Attempt to fetch production data from data logger',
};

export default function EclypseOptions(props) {
  const { meter, timezone, start, setStart, end, setEnd, setBackfilledData } =
    props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (meter !== null) {
      setStart((s) => {
        if (s === null) {
          return dayjs()
            .tz(timezone.zone)
            .subtract(1, 'month')
            .startOf('month');
        } else {
          return s.tz(timezone.zone).startOf('day');
        }
      });
      setEnd((e) => {
        if (e === null) {
          return dayjs().tz(timezone.zone).subtract(1, 'month').endOf('month');
        } else {
          return e.tz(timezone.zone).endOf('day');
        }
      });
    } else {
      setStart(null);
      setEnd(null);
    }
  }, [meter, timezone.zone, setStart, setEnd]);

  const backfill = () => {
    const message = (
      <div>
        <p>Are you sure you want backfill this data?</p>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <strong>Meter:</strong>
              </td>
              <td>{meter.name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
    dispatch(
      openConfirmDialog({
        title: 'Eclypse Backfill',
        message,
        onConfirm: async () => {
          try {
            setLoading(true);
            const payload = await new WebAPIClient(SOLAR_APP_ID).POST(
              `/resource/backfill/${meter.org_id}/${meter.meter_id}`,
              {
                backfill_method: 'eclypse',
                start: start.unix(),
                end: end.unix(),
              }
            );
            setBackfilledData(payload);
            toastr.success({
              title: 'Success',
              message: `Rows backfilled: ${payload.length}`,
            });
          } catch (error) {
            if (error?.code === 'ECONNABORTED') {
              toastr.error({
                title: 'Timeout Error',
                message: error?.message,
              });
            } else {
              errorResponseToastr(error);
            }
          } finally {
            setLoading(false);
          }
        },
      })
    );
  };

  return (
    <Grid size={{ xs: 12 }}>
      <Card raised sx={{ py: 0.5, px: 0.5 }}>
        <CardHeader
          sx={{ pt: 1, pb: 0, mr: 1 }}
          title={METHOD.name}
          subheader={METHOD.description}
          action={
            <Stack
              direction='row'
              spacing={2}
              justifyContent='flex-end'
              alignItems='center'
              divider={
                <Divider orientation='vertical' flexItem sx={{ m: 1 }} />
              }>
              {loading && (
                <Box sx={{ position: 'relative', width: 100, top: -30 }}>
                  <WidgetLoader loading={true} />
                </Box>
              )}
              <SelectDatetime
                value={start}
                setValue={setStart}
                label='Start'
                timezone={timezone}
              />
              <SelectDatetime
                value={end}
                setValue={setEnd}
                label='End'
                timezone={timezone}
              />
              <Tooltip title='send request' placement='top'>
                <span>
                  <IconButton
                    onClick={backfill}
                    disabled={(() => {
                      return start === null || end === null;
                    })()}>
                    <FontAwesomeIcon icon={['fal', 'paper-plane']} />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          }
        />
      </Card>
    </Grid>
  );
}
