import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { postSolarSite, SOLAR_APP_ID } from '../../store/solar';
import { DEMAND_APP_ID, postDemandSite } from '../../store/demand';
import { toastr } from '../CustomToast';
import SolarSiteForm, {
  defaultState as solarDefaultState,
} from '../Forms/solar/SiteForm';
import DemandSiteForm, {
  defaultState as demandDefaultState,
} from '../Forms/demand/SiteForm';
import BaseDialog from './BaseDialog';

function SiteDialog(props) {
  const { appId, open, closeDialog, defaultOrgId } = props;
  const dispatch = useDispatch();

  const defaultState = (() => {
    if (appId === SOLAR_APP_ID) return solarDefaultState;
    if (appId === DEMAND_APP_ID) return demandDefaultState;
    return solarDefaultState;
  })();
  const [site, setSite] = useState(defaultState);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setSite((prevSite) => ({ ...prevSite, org_id: defaultOrgId }));
  }, [defaultOrgId]);

  useEffect(() => {
    if (site.name.length === 0) {
      setMessage('Site name required');
    } else {
      setMessage('');
    }
  }, [site]);

  const handleClose = () => {
    closeDialog();
    setSite({ ...defaultState, org_id: defaultOrgId });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message) {
      toastr.warning({ title: message });
    } else {
      if (appId === SOLAR_APP_ID) {
        dispatch(postSolarSite(site));
      } else if (appId === DEMAND_APP_ID) {
        dispatch(postDemandSite(site));
      }

      handleClose();
    }
  };

  const id = open ? 'create-site-dialog' : '';
  return (
    <BaseDialog
      id={id}
      disabled={message.length > 0}
      submitText='Create'
      title='Create Site'
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      {appId === SOLAR_APP_ID && (
        <SolarSiteForm
          mode='create'
          site={site}
          setSite={setSite}
          handleSubmit={handleSubmit}
        />
      )}
      {appId === DEMAND_APP_ID && (
        <DemandSiteForm
          mode='create'
          site={site}
          setSite={setSite}
          handleSubmit={handleSubmit}
        />
      )}
    </BaseDialog>
  );
}

export default SiteDialog;
