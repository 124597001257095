import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { DEMAND_APP_ID } from '.';
import { ROUTES } from '../../helpers/constants';
import { toastr } from '../../components/CustomToast';
const postDemandSite = createAsyncThunk(
  'demand/postDemandSite',
  async (newSite, { dispatch, getState }) => {
    const stateSites = getState().demand.sites;
    let sites = cloneDeep(stateSites);
    try {
      dispatch(showLoading());
      const site = await new WebAPIClient(DEMAND_APP_ID).POST(
        '/resource/sites',
        newSite
      );

      toastr.success({
        title: 'Site created',
        message: site.name,
      });
      return { sites: [...sites, site] };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putDemandSite = createAsyncThunk(
  'demand/putDemandSite',
  async (updatedSite, { dispatch, getState }) => {
    const stateSites = getState().demand.sites;
    let sites = cloneDeep(stateSites);
    try {
      dispatch(showLoading());

      const site = await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/sites/${updatedSite.org_id}/${updatedSite.site_id}`,
        updatedSite
      );
      remove(sites, {
        org_id: updatedSite.org_id,
        site_id: updatedSite.site_id,
      });
      sites = concat(sites, site);

      toastr.success({ title: 'Site updated' });
      return { sites };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteDemandSite = createAsyncThunk(
  'demand/deleteDemandSite',
  async (deletedSite, { dispatch, getState }) => {
    const { sites: stateSites, meters: stateMeters } = getState().demand;
    let sites = cloneDeep(stateSites);
    let meters = cloneDeep(stateMeters);
    try {
      dispatch(showLoading());
      await new WebAPIClient(DEMAND_APP_ID).DELETE(
        `/resource/sites/${deletedSite.org_id}/${deletedSite.site_id}`
      );

      remove(sites, {
        org_id: deletedSite.org_id,
        site_id: deletedSite.site_id,
      });

      const siteMeterIds = meters
        .filter((meter) => meter.site_id === deletedSite.site_id)
        .map((meter) => meter.meter_id);

      remove(meters, (meter) => siteMeterIds.includes(meter.meter_id));

      toastr.success({ title: 'Site deleted' });
      return { sites, meters };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
      dispatch(push(ROUTES.GRIDCAP.path));
    }
  }
);

export { postDemandSite, putDemandSite, deleteDemandSite };
