import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import { calculateAverageAppCost } from '../../../helpers';
import { numberToCurrency2 } from '../../../helpers/currency';
import { DEMAND_APP_ID } from '../../../store/demand';
import IconImg from './IconImg';
import DataCard from './DataCard';
import SwipeableContainer from '../../../components/SwipeableContainer';

export default function GridcapGrid() {
  const theme = useTheme();

  const { loading, alarms, meters, users } = useSelector(
    (state) => state.demand
  );
  const { initial: costs, loading: costsLoading } = useSelector(
    (state) => state.costs
  );

  const [activeMeters, setActiveMeters] = useState([]);
  const [activeAlarms, setActiveAlarms] = useState([]);
  const [monthlyActiveUsers, setMonthlyActiveUsers] = useState([]);
  const [avgCost, setAvgCost] = useState(0);

  useEffect(() => {
    const one_day_ago = dayjs().subtract(1, 'day');
    setActiveMeters(
      meters.filter((meter) => {
        const last_ok_time = dayjs(meter.stats.last_ok_time);
        return last_ok_time > one_day_ago && meter.active;
      })
    );
  }, [meters]);

  useEffect(() => {
    setActiveAlarms(alarms.filter((alarm) => alarm.status));
  }, [alarms]);

  useEffect(() => {
    const thirtyDaysAgo = dayjs().subtract(30, 'day');
    const _users = users.filter(
      (user) => dayjs(user.last_update) > thirtyDaysAgo
    );
    setMonthlyActiveUsers(_users);
  }, [users]);

  useEffect(() => {
    const demandCosts = calculateAverageAppCost(costs, DEMAND_APP_ID);
    const gridcapCosts = calculateAverageAppCost(costs, 'gridcap');
    setAvgCost(demandCosts + gridcapCosts);
  }, [costs]);

  return (
    <Grid size={{ xs: 12 }} sx={{ maxWidth: '100vw' }}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        sx={{ pt: 1 }}>
        <IconImg iconName='gridcap' height={45} label='Gridcap' />
        <Typography variant='h4' ml={2}>
          Gridcap
        </Typography>
      </Box>
      <SwipeableContainer>
        <DataCard
          loading={loading}
          icon='router'
          value={activeMeters.length}
          label='Active Meters'
          color={theme.palette.veregy_colors.grey}
        />
        <DataCard
          loading={loading}
          icon='bell-on'
          value={activeAlarms.length}
          label='Active Alarms'
          color={theme.palette.veregy_colors.orange}
        />
        <DataCard
          loading={loading}
          icon='users'
          value={monthlyActiveUsers.length}
          label='Monthly Active Users'
          color={theme.palette.veregy_colors.blue}
        />
        <DataCard
          loading={costsLoading}
          icon='dollar-sign'
          value={numberToCurrency2.format(avgCost)}
          label='Avg Daily Cost'
          color={theme.palette.veregy_colors.green}
        />
      </SwipeableContainer>
    </Grid>
  );
}
