import http from 'axios';
import get from 'lodash/get';

import { getCurrentSession } from '../helpers/auth';
import { toastr } from '../components/CustomToast';
const CLIENT_ID = process.env.REACT_APP_ENERGYTRACER_APP_CLIENT_ID;
const WEB_API_ID = process.env.REACT_APP_ENERGYTRACER_WEB_API_URL;

export const errorResponseToastr = (err) => {
  console.error(err);
  toastr.error({
    title: 'Error',
    message: get(err, 'response.data.detail.msg', 'An Error Occured'),
  });
};
export default class WebAPIClient {
  constructor(timeout = 20000) {
    this.axios = http.create({
      baseURL: WEB_API_ID,
      timeout: timeout,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.axios.interceptors.request.use(async function (config) {
      const { accessToken } = await getCurrentSession(CLIENT_ID, WEB_API_ID);
      if (accessToken) {
        config.headers.Authorization = accessToken;
        config.headers['cognito-access-token'] = accessToken;
      }
      return config;
    });
  }

  async GET(endpoint = '', params = {}) {
    return this._handleResponse(await this.axios.get(endpoint, { params }));
  }

  async GET_DOWNLOAD(endpoint = '', headers = {}) {
    return this._handleResponse(await this.axios.get(endpoint, headers));
  }

  async POST(endpoint = '', data = {}) {
    return this._handleResponse(await this.axios.post(endpoint, data));
  }

  async PUT(endpoint = '', data = {}) {
    return this._handleResponse(await this.axios.put(endpoint, data));
  }

  async DELETE(endpoint = '') {
    return this._handleResponse(await this.axios.delete(endpoint));
  }

  _handleResponse(response) {
    return response?.data;
  }
}
