import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid2';

import { openConfirmDialog } from '../../../../../store/dialogs';
import { deleteSolarSensor, putSolarSensor } from '../../../../../store/solar';
import SensorForm from '../../../../../components/Forms/solar/SensorForm';

export default function SensorPage(props) {
  const { sensor: stateSensor } = props;
  const dispatch = useDispatch();
  const [sensor, setSensor] = useState(stateSensor);

  const handleChange = (e) => {
    const id = e.target.id || e.target.name;
    const value = e.target.value;
    setSensor((prevSensor) => ({ ...prevSensor, [id]: value }));
  };

  const handleSubmit = () => {
    dispatch(putSolarSensor(sensor));
  };

  const handleDelete = () => {
    dispatch(
      openConfirmDialog({
        title: 'Delete Sensor',
        message: <p>Are you sure you want to delete this sensor?</p>,
        onConfirm: () => dispatch(deleteSolarSensor(sensor)),
      })
    );
  };

  return (
    <Grid
      size={{ xs: 12 }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '100vw',
        px: 1,
      }}>
      <Card raised sx={{ maxWidth: 400, width: '100%', px: 2, py: 1 }}>
        <SensorForm
          mode='edit'
          sensor={sensor}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          expanded={true}
        />
        <CardActions sx={{ justifyContent: 'space-between' }}>
          <Button variant='outlined' color='error' onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='contained' onClick={handleSubmit}>
            Save
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}
