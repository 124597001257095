import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';

import { deleteDemandSite, putDemandSite } from '../../../../../store/demand';
import { openConfirmDialog } from '../../../../../store/dialogs';
import SiteForm from '../../../../../components/Forms/demand/SiteForm';

function SiteCard(props) {
  const { site: siteState } = props;
  const dispatch = useDispatch();
  const [site, setSite] = useState(siteState);

  useEffect(() => {
    setSite(siteState);
  }, [siteState]);

  const handleSubmit = () => {
    dispatch(putDemandSite(site));
  };

  const handleDelete = () => {
    const message = (
      <p>
        Are you sure you want to delete <strong>{site.name}</strong>?
      </p>
    );
    dispatch(
      openConfirmDialog({
        title: 'Delete Site',
        message: message,
        onConfirm: () => {
          dispatch(deleteDemandSite(site));
        },
      })
    );
  };

  return (
    <Card raised sx={{ maxWidth: 400, width: '100%', px: 2, py: 1 }}>
      <SiteForm
        mode='edit'
        site={site}
        setSite={setSite}
        handleSubmit={handleSubmit}
      />
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Button color='error' variant='outlined' onClick={handleDelete}>
          Delete
        </Button>
        <Button variant='contained' onClick={handleSubmit}>
          Save
        </Button>
      </CardActions>
    </Card>
  );
}

export default SiteCard;
