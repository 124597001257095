import React, { useState } from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';

import SelectMeter from './SelectMeter';
import SelectBackfillMethod, { METHODS } from './SelectBackfillMethod';
import { TIMEZONES } from '../../../../components/Selectors/SelectTimezone';
import TimezoneField from './TimezoneField';
import ProductionData from './ProductionData';

export default function Backfill() {
  const [meter, setMeter] = useState(null);
  const [timezone, setTimezone] = useState(TIMEZONES[0]);
  const [backfillMethod, setBackfillMethod] = useState(METHODS[0]);
  const [backfillStart, setBackfillStart] = useState(null);
  const [backfillEnd, setBackfillEnd] = useState(null);

  const [actualData, setActualData] = useState([]);
  const [backfilledData, setBackfilledData] = useState([]);
  const MethodComponent = backfillMethod.component;

  const handleSelectBackfillMethod = (newMethod) => {
    setBackfillMethod(newMethod);
    setBackfillStart(null);
    setBackfillEnd(null);
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ maxWidth: '100%', px: 1, mt: 1, mb: 6 }}>
      <Grid size={{ xs: 12 }}>
        <Card raised sx={{ py: 0.5 }}>
          <CardHeader
            sx={{
              '&.MuiCardHeader-root': { padding: 0 },
            }}
            title={
              <Stack
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
                divider={
                  <Divider orientation='vertical' flexItem sx={{ my: 0.5 }} />
                }>
                <SelectMeter
                  meter={meter}
                  setMeter={setMeter}
                  setTimezone={setTimezone}
                />
                <TimezoneField timezone={timezone} />
                <SelectBackfillMethod
                  method={backfillMethod}
                  setMethod={handleSelectBackfillMethod}
                />
              </Stack>
            }
          />
        </Card>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <ProductionData
          meter={meter}
          timezone={timezone}
          actualData={actualData}
          setActualData={setActualData}
          backfilledData={backfilledData}
          setBackfilledData={setBackfilledData}
          backfillStart={backfillStart}
          backfillEnd={backfillEnd}
        />
      </Grid>
      <MethodComponent
        meter={meter}
        timezone={timezone}
        start={backfillStart}
        setStart={setBackfillStart}
        end={backfillEnd}
        setEnd={setBackfillEnd}
        setBackfilledData={setBackfilledData}
      />
    </Grid>
  );
}
