import React, { useEffect, useState } from 'react';
import startCase from 'lodash/startCase';
import isIP from 'validator/lib/isIP';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MobileStepper from '@mui/material/MobileStepper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DialogTitleOptions from '../../DialogTitleOptions';
import { toastr } from '../../../CustomToast';
import LoggerForm, {
  defaultLoggerState,
} from '../../../Forms/solar/LoggerForm';
import { defaultMeterState } from '../../../Forms/solar/MeterForm';
import { defaultSensorState } from '../../../Forms/solar/SensorForm';
import { defaultInverterState } from '../../../Forms/solar/InverterForm';
import MetersForms from './MetersForms';
import InvertersForms from './InvertersForms';
import SensorsForms from './SensorsForms';
import ConfirmCreation from './ConfirmCreation';

const steps = [
  'Create Logger',
  'Create Meters',
  'Create Sensors',
  'Create Inverters',
  'Confirm Creation',
];

function CreateLoggerAndDevicesDialog(props) {
  const { open, closeDialog, orgId, siteId } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [nextMessage, setNextMessage] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [logger, setLogger] = useState(defaultLoggerState);
  const [meters, setMeters] = useState([defaultMeterState]);
  const [inverters, setInverters] = useState([defaultInverterState]);
  const [sensors, setSensors] = useState([defaultSensorState]);

  useEffect(() => {
    if (activeStep === 0) {
      const validIp = isIP(logger.ip_address);
      if (logger.name.length === 0) {
        setNextMessage('Logger name required');
      } else if (!validIp) {
        setNextMessage('Invalid IP Address');
      } else {
        setNextMessage('');
      }
    } else if (activeStep === 1) {
      const metersHaveName = meters.every((meter) => meter.name.length > 0);
      if (!metersHaveName) {
        setNextMessage('Meter name required');
      } else {
        setNextMessage('');
      }
    } else if (activeStep === 2) {
      const sensorsHaveName = sensors.every((sensor) => sensor.name.length > 0);
      if (!sensorsHaveName) {
        setNextMessage('Sensor name required');
      } else {
        setNextMessage('');
      }
    } else if (activeStep === 3) {
      const invertersHaveName = inverters.every(
        (inverter) => inverter.name.length > 0
      );
      if (!invertersHaveName) {
        setNextMessage('Inverter name required');
      } else {
        setNextMessage('');
      }
    }
  }, [activeStep, logger, meters, inverters, sensors]);

  const handleClose = () => {
    closeDialog();
    setActiveStep(0);
    setLogger(defaultLoggerState);
    setMeters([defaultMeterState]);
    setInverters([defaultInverterState]);
    setSensors([defaultSensorState]);
  };

  const handleNext = () => {
    if (nextMessage) {
      toastr.warning({ title: nextMessage });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
      <DialogTitle>
        {startCase(steps[activeStep])}
        <DialogTitleOptions handleClose={handleClose} />
      </DialogTitle>
      <DialogContent sx={isMobile ? {} : { width: '100%', minWidth: 450 }}>
        {activeStep === 0 && (
          <LoggerForm
            mode='create'
            logger={logger}
            setLogger={setLogger}
            handleSubmit={handleNext}
          />
        )}
        {activeStep === 1 && (
          <MetersForms
            meters={meters}
            setMeters={setMeters}
            handleSubmit={handleNext}
          />
        )}
        {activeStep === 2 && (
          <SensorsForms
            sensors={sensors}
            setSensors={setSensors}
            handleSubmit={handleNext}
          />
        )}
        {activeStep === 3 && (
          <InvertersForms
            inverters={inverters}
            setInverters={setInverters}
            handleSubmit={handleNext}
          />
        )}
        {activeStep === 4 && (
          <ConfirmCreation
            handleClose={handleClose}
            orgId={orgId}
            siteId={siteId}
            logger={logger}
            meters={meters}
            inverters={inverters}
            sensors={sensors}
          />
        )}
      </DialogContent>
      <MobileStepper
        variant='dots'
        steps={steps.length}
        position='static'
        activeStep={activeStep}
        nextButton={
          <Tooltip title={nextMessage} placement='top'>
            <span>
              <Button
                variant='outlined'
                onClick={handleNext}
                disabled={
                  activeStep === steps.length - 1 || nextMessage.length > 0
                }>
                <Typography variant='body2' sx={{ mr: 1 }}>
                  Next
                </Typography>
                <FontAwesomeIcon icon={['fal', 'chevron-right']} />
              </Button>
            </span>
          </Tooltip>
        }
        backButton={
          <Button
            variant='outlined'
            onClick={handleBack}
            disabled={activeStep === 0}>
            <FontAwesomeIcon icon={['fal', 'chevron-left']} />
            <Typography variant='body2' sx={{ ml: 1 }}>
              Back
            </Typography>
          </Button>
        }
      />
    </Dialog>
  );
}

export default CreateLoggerAndDevicesDialog;
