import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LineChart, Line, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import { formatTimestamp, generateTicks } from '../../../../../helpers/date';
import WidgetLoader from '../../../../../components/Loaders/WidgetLoader';
import ChartTooltip from './Tooltip';

export const DATA_FEEDS = {
  inst_loads: { color: 'blue', unit: 'kW' },
  frcst_inst_loads: { color: '#82ca9d', unit: 'kW' },
  day_ahead_lmps: { color: 'blue', unit: '' },
  real_time_lmps: { color: 'blue', unit: '' },
  real_time_hourly_lmps: { color: 'blue', unit: '' },
  real_time_unverified_lmps: { color: 'blue', unit: '' },
  published_loads: { color: 'blue', unit: 'kW' },
};

function WeatherChart({
  chartData,
  selectedMetrics,
  range,
  timezone,
  loading,
}) {
  if (loading)
    return (
      <Box sx={{ height: 400, width: '100%' }}>
        <WidgetLoader loading={loading} height={50} />
      </Box>
    );

  if (isEmpty(chartData))
    return (
      <Box
        sx={{
          height: 400,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography>No data available</Typography>
      </Box>
    );

  const ticks = generateTicks(range);
  return (
    <ResponsiveContainer
      key='pjm-markets-data-feed-chart-responsive-container'
      width='100%'
      height={400}>
      <LineChart
        width='100%'
        height={400}
        data={chartData}
        margin={{
          top: 5,
          right: 2,
          left: 0,
          bottom: 5,
        }}>
        <XAxis
          type='number'
          dataKey='timestamp'
          ticks={ticks}
          tickMargin={15}
          domain={[range.start.unix(), range.end.unix()]}
          tickFormatter={(unixTime) => {
            return formatTimestamp(unixTime, timezone, 'MMM Do');
          }}
        />
        <Tooltip
          content={<ChartTooltip timezone={timezone} metrics={DATA_FEEDS} />}
        />
        {map(selectedMetrics, (metric) => {
          const settings = DATA_FEEDS[metric];
          return (
            <Line
              dot={false}
              key={`line-${metric}`}
              dataKey={metric}
              stroke={settings.color}
              connectNulls
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
}

WeatherChart.propTypes = {
  chartData: PropTypes.array,
  selectedMetrics: PropTypes.array,
  range: PropTypes.object,
  timezone: PropTypes.object,
  loading: PropTypes.bool,
};

export default WeatherChart;
