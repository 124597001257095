import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';

import Card from '@mui/material/Card';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import {
  deleteSolarMembership,
  putSolarMembership,
  SOLAR_APP_ID,
} from '../../store/solar';
import {
  deleteDemandMembership,
  DEMAND_APP_ID,
  putDemandMembership,
} from '../../store/demand';
import { openConfirmDialog } from '../../store/dialogs';
import useOrgNamePredicate from '../../store/hooks/useOrgNamePredicate';
import useCompareOrgName from '../../store/hooks/useCompareOrgName';
import BaseTable from './BaseTable';
import HeaderEditCell from './cells/HeaderEditCell';
import EditCell from './cells/EditCell';
import WidgetLoader from '../Loaders/WidgetLoader';
import MembershipDialog from '../Dialogs/MembershipDialog';
import {
  MembershipRoleTypeProvider,
  OrganizationIdNameTypeProvider,
} from './providers';
import { findRole } from '../../helpers/constants';

function MembershipsTable(props) {
  const { orgId, appId, mx = 1 } = props;
  const dispatch = useDispatch();
  const {
    loading,
    users,
    memberships: allMemberships,
    organizations,
  } = useSelector((state) => state[appId]);

  const orgNamePredicate = useOrgNamePredicate(appId);
  const compareOrgName = useCompareOrgName(appId);

  const [integratedFilteringColumnExtensions] = useState([
    { columnName: 'org_id', predicate: orgNamePredicate },
  ]);
  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'org_id', compare: compareOrgName },
  ]);
  const [memberships, setMemberships] = useState([]);
  const [open, setOpen] = useState(false);

  const [columns] = useState([
    { title: 'Organization', name: 'org_id', width: 0.25 },
    { title: 'Name', name: 'name', width: 0.2 },
    { title: 'Email', name: 'email', width: 0.35 },
    { title: 'Role', name: 'role', width: 0.15 },
  ]);

  useEffect(() => {
    let _memberships = allMemberships;
    if (orgId) {
      _memberships = filter(_memberships, { org_id: orgId });
    }
    _memberships = map(_memberships, (membership) => {
      const user = find(users, { user_id: membership.user_id });
      return {
        ...membership,
        name: get(user, 'name', ''),
        email: get(user, 'email', ''),
      };
    });
    setMemberships(_memberships);
  }, [allMemberships, users, orgId]);

  const handleEditClick = (organization, value) => {
    const updatedMembership = { ...organization, role: value };
    const role = findRole(appId, value);
    const userName = updatedMembership.name || updatedMembership.email;
    const message = (
      <div>
        <p>Are you sure you want to update this membership?</p>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <strong>User:</strong>
              </td>
              <td>{userName}</td>
            </tr>
            <tr>
              <td>
                <strong>New Role:</strong>
              </td>
              <td>{role.toLowerCase()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    dispatch(
      openConfirmDialog({
        title: 'Update Membership',
        message: message,
        onConfirm: () => {
          if (appId === SOLAR_APP_ID) {
            dispatch(putSolarMembership(updatedMembership));
          } else if (appId === DEMAND_APP_ID) {
            dispatch(putDemandMembership(updatedMembership));
          }
        },
      })
    );
  };

  const handleDeleteClick = (membership) => {
    const message = <p>Are you sure you want to delete this membership?</p>;
    dispatch(
      openConfirmDialog({
        title: 'Delete Membership',
        message: message,
        onConfirm: () => {
          if (appId === SOLAR_APP_ID) {
            dispatch(deleteSolarMembership(membership));
          } else if (appId === DEMAND_APP_ID) {
            dispatch(deleteDemandMembership(membership));
          }
        },
      })
    );
  };

  return (
    <Card raised sx={{ maxWidth: 900, width: '100%', mx, px: 1 }}>
      <WidgetLoader loading={loading} />
      <BaseTable
        minWidth={900}
        rows={memberships}
        columns={columns}
        editColumn={
          <TableEditColumn
            width={42}
            headerCellComponent={(props) => {
              return (
                <HeaderEditCell
                  disabled={false}
                  tooltipText='Create Membership'
                  handleClick={() => setOpen(true)}
                  {...props}
                />
              );
            }}
            cellComponent={(props) => (
              <EditCell
                disabled={false}
                tooltipText='Delete Membership'
                icon={['fal', 'trash']}
                // eslint-disable-next-line
                handleClick={() => handleDeleteClick(props.row)}
                {...props}
              />
            )}
          />
        }>
        <OrganizationIdNameTypeProvider
          for={['org_id']}
          organizations={organizations}
        />
        <MembershipRoleTypeProvider
          for={['role']}
          appId={appId}
          handleClick={handleEditClick}
        />

        <EditingState />
        <FilteringState />
        <SortingState
          defaultSorting={[
            { columnName: 'org_id', direction: 'asc' },
            { columnName: 'role', direction: 'asc' },
          ]}
        />
        <PagingState defaultCurrentPage={0} pageSize={10} />

        <IntegratedFiltering
          columnExtensions={integratedFilteringColumnExtensions}
        />
        <IntegratedSorting
          columnExtensions={integratedSortingColumnExtensions}
        />
        <IntegratedPaging />
      </BaseTable>
      <MembershipDialog
        appId={appId}
        orgId={orgId}
        open={open}
        setOpen={setOpen}
      />
    </Card>
  );
}

export default MembershipsTable;
