import React from 'react';
import Grid from '@mui/material/Grid2';

import UsersTable from './Table/UsersTable';

function UsersGrid(props) {
  const { appId } = props;
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{
        maxWidth: '100%',
        mt: 1,
      }}>
      <Grid
        size={{ xs: 12 }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
          px: 1,
        }}>
        <UsersTable appId={appId} />
      </Grid>
    </Grid>
  );
}

export default UsersGrid;
