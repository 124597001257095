import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postSolarSensor } from '../../../store/solar';
import { toastr } from '../../CustomToast';
import SensorForm, { defaultSensorState } from '../../Forms/solar/SensorForm';
import BaseDialog from '../BaseDialog';

function SensorDialog(props) {
  const { open, closeDialog, orgId, loggerId, meterId } = props;
  const dispatch = useDispatch();

  const meters = useSelector((state) => state.solar.meters);
  const sensors = useSelector((state) => state.solar.sensors);

  const [parentIndex, setParentIndex] = useState(1);
  const [sensor, setSensor] = useState(defaultSensorState);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const meterIds = meters
      .filter((meter) => meter.logger_id === loggerId)
      .map((meter) => meter.meter_id);
    const loggerSensors = sensors.filter((sensor) =>
      meterIds.includes(sensor.meter_id)
    );
    setParentIndex(loggerSensors.length + 1);
  }, [orgId, meters, sensors, loggerId, meterId]);

  useEffect(() => {
    setSensor((prevSensor) => ({
      ...prevSensor,
      org_id: orgId,
      meter_id: meterId,
      parent_index: parentIndex,
      name: `Sensor ${parentIndex}`,
    }));
  }, [orgId, meterId, parentIndex]);

  useEffect(() => {
    if (sensor.name.length === 0) {
      setMessage('Sensor name required');
    } else {
      setMessage('');
    }
  }, [sensor]);

  const handleChange = (e) => {
    const id = e.target.id || e.target.name;
    const value = e.target.value;
    setSensor((prevSensor) => ({ ...prevSensor, [id]: value }));
  };

  const handleClose = () => {
    closeDialog();
    setSensor({
      ...defaultSensorState,
      org_id: orgId,
      meter_id: meterId,
      parent_index: parentIndex,
      name: `Sensor ${parentIndex}`,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message) {
      toastr.warning({ title: message });
    } else {
      dispatch(postSolarSensor(sensor));
      handleClose();
    }
  };

  const id = open ? 'create-sensor-dialog' : '';
  return (
    <BaseDialog
      id={id}
      disabled={message.length > 0}
      submitText='Create'
      title='Create Sensor'
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <SensorForm
        mode='create'
        sensor={sensor}
        setSensor={setSensor}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        expanded={true}
      />
    </BaseDialog>
  );
}

export default SensorDialog;
