import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import { ROUTES } from '../../../../../helpers/constants';
import { DEMAND_APP_ID } from '../../../../../store/demand';
import OrganizationResources from './Resources';
import PortfolioMembershipsTable from '../../../../../components/Table/PortfolioMembershipsTable';
import MembershipsTable from '../../../../../components/Table/MembershipsTable';
import LicensesTable from '../../../../../components/Table/LicensesTable';
import OrganizationCard from '../../../../../components/OrganizationCard';
import NiagaraGridItems from './NiagaraGridItems';
import {
  StyledSubtab,
  StyledSubtabs,
} from '../../../../../components/StyledSubtabs';

export default function OrganizationPage(props) {
  const { organization } = props;
  const [tab, setTab] = useState(0);

  const handleChange = (e, newValue) => {
    setTab(newValue);
  };

  const label = (() => {
    if (tab === 0 || tab === 1) {
      return null;
    }
    if (tab === 2) return 'Members';
    if (tab === 3) {
      return organization.is_portfolio ? 'Portfolio Members' : 'Licenses';
    }
  })();

  return (
    <>
      <Grid
        size={{ xs: 12 }}
        sx={{ display: 'flex', justifyContent: 'center' }}>
        <StyledSubtabs
          value={tab}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <StyledSubtab
            icon={
              <FontAwesomeIcon
                icon={[
                  'fal',
                  organization?.is_portfolio ? 'folder' : 'buildings',
                ]}
                size='xl'
              />
            }
          />
          <StyledSubtab
            icon={<FontAwesomeIcon icon={['fal', 'sitemap']} size='xl' />}
          />
          <StyledSubtab
            icon={<FontAwesomeIcon icon={['fal', 'user-friends']} size='xl' />}
          />
          <StyledSubtab
            icon={
              <FontAwesomeIcon
                icon={[
                  'fal',
                  organization?.is_portfolio
                    ? 'folder-tree'
                    : 'file-certificate',
                ]}
                size='xl'
              />
            }
          />
        </StyledSubtabs>
      </Grid>
      {label && (
        <Grid
          size={{ xs: 12 }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mx: 1,
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Typography variant='body1' align='center'>
              {label}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid
        size={{ xs: 12 }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
          px: 1,
        }}>
        {tab === 0 && (
          <OrganizationCard
            organization={organization}
            appId={DEMAND_APP_ID}
            path={ROUTES.GRIDCAP.path}
          />
        )}
        {tab === 1 && <OrganizationResources organization={organization} />}
        {tab === 2 && (
          <MembershipsTable
            orgId={organization.org_id}
            appId={DEMAND_APP_ID}
            mx={0}
          />
        )}
        {tab === 3 &&
          (organization.is_portfolio ? (
            <PortfolioMembershipsTable
              portfolio={organization}
              appId={DEMAND_APP_ID}
            />
          ) : (
            <LicensesTable orgId={organization.org_id} appId={DEMAND_APP_ID} />
          ))}
      </Grid>
      {tab === 0 && !organization.is_portfolio && (
        <NiagaraGridItems organization={organization} />
      )}
    </>
  );
}
