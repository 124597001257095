import { createAsyncThunk } from '@reduxjs/toolkit';
import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { SOLAR_APP_ID } from '.';
import { toastr } from '../../components/CustomToast';
const postSolarUser = createAsyncThunk(
  'solar/postSolarUser',
  async (newUser, { dispatch, getState }) => {
    const stateUsers = getState().solar.users;
    let users = cloneDeep(stateUsers);
    try {
      dispatch(showLoading());
      const user = await new WebAPIClient(SOLAR_APP_ID).POST(
        '/resource/users',
        newUser
      );
      users = concat(users, user);
      toastr.success({
        title: 'User Created',
        message: user.email,
      });
      return { users };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putSolarUser = createAsyncThunk(
  'solar/putSolarUser',
  async (updatedUser, { dispatch, getState }) => {
    const stateUsers = getState().solar.users;
    let users = cloneDeep(stateUsers);
    try {
      dispatch(showLoading());
      const user = await new WebAPIClient(SOLAR_APP_ID).PUT(
        `/resource/users/${updatedUser.user_id}`,
        updatedUser
      );

      remove(users, { user_id: user.user_id });
      users = concat(users, user);

      toastr.success({ title: 'User Updated' });
      return { users };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteSolarUser = createAsyncThunk(
  'solar/deleteSolarUser',
  async (userId, { dispatch, getState }) => {
    const stateUsers = getState().solar.users;
    let users = cloneDeep(stateUsers);
    try {
      dispatch(showLoading());
      await new WebAPIClient(SOLAR_APP_ID).DELETE(`/resource/users/${userId}`);
      remove(users, { user_id: userId });

      return { users };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const generateSolarAPIKey = createAsyncThunk(
  'solar/generateSolarAPIKey',
  async (userId, { dispatch, getState }) => {
    try {
      const { users } = getState().solar;

      dispatch(showLoading());
      let updatedUser = await new WebAPIClient(SOLAR_APP_ID).PUT(
        `/resource/generate_api_key/${userId}`
      );
      let _users = cloneDeep(users);
      remove(_users, { user_id: updatedUser.user_id });

      toastr.success({
        title: 'API Key Generated',
        message: updatedUser.email,
      });
      return { users: concat(_users, updatedUser) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { postSolarUser, putSolarUser, deleteSolarUser, generateSolarAPIKey };
