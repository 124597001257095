import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
function SelectUserAgent(props) {
  const {
    userAgents,
    selectedUserAgent,
    setSelectedUserAgent,
    showDetails,
    setShowDetails,
  } = props;
  const theme = useTheme();

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedUserAgent(e.target.value);
  };

  const selectedUserAgentIdx = userAgents.findIndex(
    (ua) => ua === selectedUserAgent
  );
  const selectedUserAgentText = showDetails
    ? selectedUserAgent
    : `User Agent ${selectedUserAgentIdx + 1}`;
  return (
    <FormControl
      variant='standard'
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <Box sx={{ mr: 2 }}>
        <InputLabel
          id='select-user-agent-label'
          sx={{ textAlign: 'center', width: '9rem' }}>
          Select User Agent
        </InputLabel>
        <Select
          disableUnderline
          labelId='select-user-agent-label'
          name='select-user-agent'
          value={selectedUserAgent || 'all'}
          renderValue={() => (
            <ListItemText
              sx={{ color: theme.palette.primary.main, my: 0 }}
              primary={selectedUserAgent ? selectedUserAgentText : 'All'}
              slotProps={{
                primary: { align: 'center' },
              }}
            />
          )}
          onChange={handleSelect}
          IconComponent={() => null}
          inputProps={{
            sx: {
              padding: '0 !important',
              color: theme.palette.primary.main,
              ...theme.typography.body2,
            },
          }}
          MenuProps={{ MenuListProps: { disablePadding: true } }}
          sx={{
            minWidth: 100,
            maxWidth: 200,
            backgroundColor: 'unset',
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
            },
          }}>
          <MenuItem
            key='all'
            value=''
            sx={{
              color: theme.palette.primary.main,
              ...theme.typography.body2,
            }}>
            All
          </MenuItem>
          {userAgents.map((userAgent, idx) => {
            return (
              <MenuItem
                key={userAgent}
                value={userAgent}
                sx={{
                  color: theme.palette.primary.main,
                  ...theme.typography.body2,
                }}>
                {showDetails ? userAgent : `User Agent ${idx + 1}`}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <ToggleButton
        size='small'
        value='check'
        selected={showDetails}
        onChange={() => setShowDetails((prevSelected) => !prevSelected)}>
        <FontAwesomeIcon icon={['fal', showDetails ? 'eye' : 'eye-slash']} />
      </ToggleButton>
    </FormControl>
  );
}

SelectUserAgent.propTypes = {
  appId: PropTypes.string,
  ipAddresses: PropTypes.array,
  selectedUserAgent: PropTypes.string,
  setSelectedUserAgent: PropTypes.func,
};

export default SelectUserAgent;
