import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';

import { showLoading, hideLoading } from '../../../../store/app';
import { toastr } from '../../../../components/CustomToast';
import WebAPIClient, {
  errorResponseToastr,
} from '../../../../api/_energytracer';
import {
  downloadEnergyTracerFile,
  REPORT_OPTIONS,
} from '../../../../helpers/energytracer/downloads';

export default function ReportTools() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleLoading = (loading) => {
    if (loading) {
      setIsLoading(true);
      dispatch(showLoading());
    } else {
      setIsLoading(false);
      dispatch(hideLoading());
    }
  };

  const handleOpenReponse = (response, report) => {
    downloadEnergyTracerFile(response, report);
  };

  const handleDownloadLicenseReport = async () => {
    const report = REPORT_OPTIONS['license'];
    handleLoading(true);
    try {
      const response = await new WebAPIClient(60000).GET_DOWNLOAD(
        `/licenses/license_report`,
        {
          ...report['opts'],
        }
      );
      handleOpenReponse(response, report);
      handleLoading(false);
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleDownloadUserReport = async () => {
    const report = REPORT_OPTIONS['user'];
    handleLoading(true);
    try {
      const response = await new WebAPIClient(60000).GET_DOWNLOAD(
        `/licenses/user_report`,
        {
          ...report['opts'],
        }
      );
      handleOpenReponse(response, report);
      handleLoading(false);
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleDownloadProviderBreakdownReport = async () => {
    const report = REPORT_OPTIONS['provider_breakdown'];
    handleLoading(true);
    try {
      const response = await new WebAPIClient(60000).GET_DOWNLOAD(
        `/providers/provider_breakdown_report`,
        {
          ...report['opts'],
        }
      );
      handleOpenReponse(response, report);
      handleLoading(false);
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleDownloadProviderOrganizationReport = async () => {
    const report = REPORT_OPTIONS['provider_organization'];
    handleLoading(true);
    try {
      const response = await new WebAPIClient(60000).GET_DOWNLOAD(
        `/providers/provider_organization_report`,
        {
          ...report['opts'],
        }
      );
      handleOpenReponse(response, report);
      handleLoading(false);
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleSendLicenseReportEmails = async () => {
    handleLoading(true);
    try {
      await new WebAPIClient().GET(`/licenses/license_report_email`);
      toastr.success({ title: 'Emails Sent' });
      handleLoading(false);
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div>
      <br></br>
      <Button
        type='submit'
        onClick={handleDownloadLicenseReport}
        color='primary'
        variant='contained'
        disabled={isLoading}>
        Download License Report
      </Button>
      <br></br>
      <br></br>
      <Button
        type='submit'
        onClick={handleDownloadUserReport}
        color='primary'
        variant='contained'
        disabled={isLoading}>
        Download User Report
      </Button>
      <br></br>
      <br></br>
      <Button
        type='submit'
        onClick={handleDownloadProviderBreakdownReport}
        color='primary'
        variant='contained'
        disabled={isLoading}>
        Download Provider Breakdown Report
      </Button>
      <br></br>
      <br></br>
      <Button
        type='submit'
        onClick={handleDownloadProviderOrganizationReport}
        color='primary'
        variant='contained'
        disabled={isLoading}>
        Download Provider Organization Report
      </Button>
      <br></br>
      <br></br>
      <Button
        type='submit'
        onClick={handleSendLicenseReportEmails}
        color='primary'
        variant='contained'
        disabled={isLoading}>
        Send License Report Emails
      </Button>
      <br></br>
      <br></br>
    </div>
  );
}
