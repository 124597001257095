import { createAsyncThunk } from '@reduxjs/toolkit';
import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { SOLAR_APP_ID } from '.';
import { toastr } from '../../components/CustomToast';

const postSolarOrganization = createAsyncThunk(
  'solar/postSolarOrganization',
  async (newOrganization, { dispatch, getState }) => {
    const stateOrganizations = getState().solar.organizations;
    const stateLicenses = getState().solar.licenses;

    let organizations = cloneDeep(stateOrganizations);
    let licenses = cloneDeep(stateLicenses);
    try {
      dispatch(showLoading());
      const { organization, licenses: newLicenses } = await new WebAPIClient(
        SOLAR_APP_ID
      ).POST('/resource/organizations', newOrganization);

      toastr.success({
        title: 'Organization created',
        message: organization.name,
      });
      return {
        organizations: [...organizations, organization],
        licenses: [...licenses, ...newLicenses],
        newOrg: organization,
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putSolarOrganization = createAsyncThunk(
  'solar/putSolarOrganization',
  async (updatedOrganization, { dispatch, getState }) => {
    const stateOrganizations = getState().solar.organizations;
    let organizations = cloneDeep(stateOrganizations);
    try {
      dispatch(showLoading());

      const organization = await new WebAPIClient(SOLAR_APP_ID).PUT(
        `/resource/organizations/${updatedOrganization.org_id}`,
        updatedOrganization
      );

      remove(organizations, { org_id: organization.org_id });
      organizations = concat(organizations, organization);

      toastr.success({ title: 'Organization updated' });
      return { organizations };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putSolarLicense = createAsyncThunk(
  'solar/putSolarLicense',
  async (license, { getState, requestId, dispatch }) => {
    try {
      const { currentRequestId, loading, licenses } = getState().solar;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const updatedLicense = await new WebAPIClient(SOLAR_APP_ID).PUT(
        `/resource/licenses/${license.org_id}/${license.name}`,
        license
      );

      let _licenses = cloneDeep(licenses);
      remove(_licenses, (license) => {
        return (
          license.org_id === updatedLicense.org_id &&
          license.name === updatedLicense.name
        );
      });

      toastr.success({ title: 'License updated' });
      return { licenses: concat(_licenses, updatedLicense) };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { postSolarOrganization, putSolarOrganization, putSolarLicense };
