import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';

import WeatherChartContainer from './WeatherChart';
import {
  StyledSubtab,
  StyledSubtabs,
} from '../../../../components/StyledSubtabs';
import ModelingChartContainer from './ModelingChart';

const WEATHER = 'Aggregated Weather';
const PJM_MARKETS = 'PJM Markets';
const SUBTABS = [WEATHER, PJM_MARKETS];

export default function PJMMarkets(props) {
  const [tab, setTab] = useState(WEATHER);

  const handleSelect = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Grid
      container
      justifyContent='center'
      spacing={1}
      sx={{ maxWidth: '100%', px: 1, mt: 1 }}>
      <Grid
        size={{ xs: 12 }}
        sx={{ mx: 1, display: 'flex', justifyContent: 'center' }}>
        <StyledSubtabs
          variant='fullWidth'
          sx={{ maxWidth: 400, width: '100%' }}
          value={tab}
          onChange={handleSelect}>
          {SUBTABS.map((tab) => (
            <StyledSubtab key={tab} label={tab} value={tab} />
          ))}
        </StyledSubtabs>
      </Grid>
      {tab === WEATHER && <WeatherChartContainer />}
      {tab === PJM_MARKETS && <ModelingChartContainer />}
    </Grid>
  );
}
