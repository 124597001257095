import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { ROUTES } from '../../../../../helpers/constants';
import generateNodes, {
  generateSiteTree,
} from '../../../../../helpers/solarTree';
import { SOLAR_APP_ID } from '../../../../../store/solar';
import ResourceTree from '../../../../../components/ResourceTree';
import SiteDialog from '../../../../../components/Dialogs/SiteDialog';
import OrganizationDialog from '../../../../../components/Dialogs/OrganizationDialog';
import CreateResourceListButton from '../../../../../components/Buttons/CreateResourceListButton';

const CreateComponent = (props) => {
  const { organization } = props;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CreateResourceListButton
        label={`Create ${organization.is_portfolio ? 'Organization' : 'Site'}`}
        setOpen={setOpen}
      />
      {organization.is_portfolio ? (
        <OrganizationDialog
          appId={SOLAR_APP_ID}
          type='organization'
          open={open}
          closeDialog={handleClose}
          defaultPortfolioId={organization.org_id}
        />
      ) : (
        <SiteDialog
          appId={SOLAR_APP_ID}
          open={open}
          closeDialog={handleClose}
          defaultOrgId={organization.org_id}
        />
      )}
    </>
  );
};

export default function OrganizationResources(props) {
  const { organization } = props;
  const solarState = useSelector((state) => state.solar);

  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    const {
      portfolioMemberships,
      organizations,
      sites,
      loggers,
      meters,
      inverters,
      sensors,
    } = solarState;
    if (organization.is_portfolio) {
      const childRelations = filter(portfolioMemberships, {
        portfolio_id: organization.org_id,
      });
      const childOrgs = [];

      each(childRelations, (relation) => {
        let organization = find(organizations, { org_id: relation.member_id });
        if (organization) childOrgs.push(organization);
      });
      setNodes(
        generateNodes({
          ...solarState,
          portoflios: [],
          organizations: childOrgs,
        })
      );
    } else {
      let orgSites = filter(sites, { org_id: organization.org_id });
      orgSites = sortBy(orgSites, 'name');
      const nodes = map(orgSites, (site) =>
        generateSiteTree(site, loggers, meters, inverters, sensors)
      );
      setNodes(nodes);
    }
  }, [organization, solarState]);

  return (
    <ResourceTree
      nodes={nodes}
      path={ROUTES.LIGHTLEVEL.path}
      createComponent={<CreateComponent organization={organization} />}
    />
  );
}
