import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import { showLoading, hideLoading } from '../../../../store/app';
import { toastr } from '../../../../components/CustomToast';
import WebAPIClient, {
  errorResponseToastr,
} from '../../../../api/_energytracer';

const REPULL_OPTIONS = {
  credential: {
    option: 'Credential',
    name: 'Credential',
    name2: false,
    name3: false,
    url: '/plug/repull/credential',
  },
  account: {
    option: 'Account',
    name: 'Account',
    name2: false,
    name3: false,
    url: '/plug/repull/account',
  },
  meter: {
    option: 'Meter',
    name: 'Account',
    name2: 'Meter',
    name3: false,
    url: '/plug/repull/meter',
  },
  statement: {
    option: 'Statement',
    name: 'Account',
    name2: 'Statement',
    name3: false,
    url: '/plug/repull/statement',
  },
  bill: {
    option: 'Bill',
    name: 'Account',
    name2: 'Meter',
    name3: 'Statement',
    url: '/plug/repull/bill',
  },
};

const TRIM_OPTIONS = {
  organization: {
    option: 'Organization',
    url: '/plug/trim/organization',
  },
  credential: {
    option: 'Credential',
    url: '/plug/trim/credential',
  },
  account: {
    option: 'Account',
    url: '/plug/trim/account',
  },
};

const TRIM_TYPES = {
  urjanet: 'Urjanet',
  manual: 'Manual',
  both: 'Both',
};

const CREDENTIAL_OPTIONS = {
  reactivate: {
    option: 'Reactivate',
    url: '/providers/reactivate_plug_credential',
  },
  deactivate: {
    option: 'Deactivate',
    url: '/providers/deactivate_plug_credential',
  },
};

export default function PlugTools() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [repullOption, setRepullOption] = useState('credential');
  const [repullId, setRepullId] = useState(null);
  const [repullId2, setRepullId2] = useState(null);
  const [repullId3, setRepullId3] = useState(null);

  const [trimOption, setTrimOption] = useState('organization');
  const [trimId, setTrimId] = useState(null);
  const [trimType, setTrimType] = useState('urjanet');

  const [credentialOption, setCredentialOption] = useState('deactivate');
  const [credentialCredentialId, setCredentialCredentialId] = useState(null);

  useEffect(() => {
    setRepullId(null);
    setRepullId2(null);
    setRepullId3(null);
  }, [repullOption]);

  useEffect(() => {
    setTrimId(null);
  }, [trimOption]);

  const handleLoading = (loading) => {
    if (loading) {
      setIsLoading(true);
      dispatch(showLoading());
    } else {
      setIsLoading(false);
      dispatch(hideLoading());
    }
  };

  const handleRepullResource = async () => {
    handleLoading(true);
    let url = REPULL_OPTIONS[repullOption]['url'] + '/' + repullId;
    if (REPULL_OPTIONS[repullOption]['name2']) {
      url = url + '/' + repullId2;
    }
    if (REPULL_OPTIONS[repullOption]['name3']) {
      url = url + '/' + repullId3;
    }

    try {
      await new WebAPIClient().GET(url);
      toastr.success({
        title: 'Repull in progress. Please check back in a few minutes',
      });
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      handleLoading(false);
      dispatch(hideLoading());
    }
  };

  const handleTrimResource = async () => {
    handleLoading(true);
    let url = TRIM_OPTIONS[trimOption]['url'] + '/' + trimId + '/' + trimType;
    try {
      await new WebAPIClient().GET(url);
      toastr.success({
        title: 'Trim in progress. Please check back in a few minutes',
      });
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      handleLoading(false);
      dispatch(hideLoading());
    }
  };

  const handleCredentialUpdate = async () => {
    handleLoading(true);
    let url =
      CREDENTIAL_OPTIONS[credentialOption]['url'] +
      '/' +
      credentialCredentialId;
    try {
      await new WebAPIClient().GET(url);
      toastr.success({ title: 'Credential Updated' });
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      handleLoading(false);
      dispatch(hideLoading());
    }
  };

  return (
    <div>
      <h3>Repull Plug Bills</h3>
      <form>
        <List disablePadding>
          <ListItem disableGutters>
            <FormControl id='repull' variant='standard' fullWidth>
              <InputLabel id='repull-label'>Repull Option</InputLabel>
              <Select
                labelId='repull-label'
                name='repull'
                onChange={(e) => {
                  setRepullOption(e.target.value);
                }}
                value={repullOption}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}
                sx={{
                  backgroundColor: 'unset',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                <MenuItem value=''></MenuItem>
                {Object.keys(REPULL_OPTIONS).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {REPULL_OPTIONS[key]['option']}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>

          <ListItem disableGutters>
            <TextField
              fullWidth
              variant='standard'
              id='value'
              label={`${REPULL_OPTIONS[repullOption]['name']} ID`}
              value={repullId ? repullId : ''}
              onChange={(e) => setRepullId(e.target.value)}
            />
          </ListItem>
          {repullOption && REPULL_OPTIONS[repullOption]['name2'] !== false && (
            <ListItem disableGutters>
              <TextField
                fullWidth
                variant='standard'
                id='value'
                label={`${REPULL_OPTIONS[repullOption]['name2']} ID`}
                value={repullId2 ? repullId2 : ''}
                onChange={(e) => setRepullId2(e.target.value)}
              />
            </ListItem>
          )}
          {repullOption && REPULL_OPTIONS[repullOption]['name3'] !== false && (
            <ListItem disableGutters>
              <TextField
                fullWidth
                variant='standard'
                id='value'
                label={`${REPULL_OPTIONS[repullOption]['name3']} ID`}
                value={repullId3 ? repullId3 : ''}
                onChange={(e) => setRepullId3(e.target.value)}
              />
            </ListItem>
          )}

          <Button
            type='submit'
            onClick={handleRepullResource}
            color='primary'
            variant='contained'
            disabled={
              !repullId ||
              (REPULL_OPTIONS[repullOption]['name2'] && !repullId2) ||
              (REPULL_OPTIONS[repullOption]['name3'] && !repullId3) ||
              isLoading
            }>
            Repull Resource
          </Button>
        </List>
      </form>
      <br></br>
      <h3>Trim Bills</h3>
      <form>
        <List disablePadding>
          <ListItem disableGutters>
            <FormControl id='trim-type' variant='standard' fullWidth>
              <InputLabel id='trim-type-label'>Trim Type</InputLabel>
              <Select
                labelId='trim-type-label'
                name='trim-type'
                onChange={(e) => {
                  setTrimType(e.target.value);
                }}
                value={trimType}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}
                sx={{
                  backgroundColor: 'unset',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                <MenuItem value=''></MenuItem>
                {Object.keys(TRIM_TYPES).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {TRIM_TYPES[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>

          <ListItem disableGutters>
            <FormControl id='trim-option' variant='standard' fullWidth>
              <InputLabel id='trim-option-label'>Update Method</InputLabel>
              <Select
                labelId='trim-option-label'
                name='trim-option'
                onChange={(e) => {
                  setTrimOption(e.target.value);
                }}
                value={trimOption}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}
                sx={{
                  backgroundColor: 'unset',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                <MenuItem value=''></MenuItem>
                {Object.keys(TRIM_OPTIONS).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {TRIM_OPTIONS[key]['option']}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem disableGutters>
            <TextField
              fullWidth
              variant='standard'
              id='value'
              label={`${TRIM_OPTIONS[trimOption]['option']} ID`}
              value={trimId ? trimId : ''}
              onChange={(e) => setTrimId(e.target.value)}
            />
          </ListItem>
          <Button
            type='submit'
            onClick={handleTrimResource}
            color='primary'
            variant='contained'
            disabled={!trimId || isLoading}>
            Update Resource
          </Button>
        </List>
      </form>
      <br></br>
      <h3>Update Credential Status</h3>
      <form>
        <List disablePadding>
          <ListItem disableGutters>
            <FormControl id='credential-option' variant='standard' fullWidth>
              <InputLabel id='credential-option-label'>
                Update Credential Option
              </InputLabel>
              <Select
                labelId='credential-option-label'
                name='credential-option'
                onChange={(e) => {
                  setCredentialOption(e.target.value);
                }}
                value={credentialOption}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}
                sx={{
                  backgroundColor: 'unset',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                <MenuItem value=''></MenuItem>
                {Object.keys(CREDENTIAL_OPTIONS).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {CREDENTIAL_OPTIONS[key].option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>

          <ListItem disableGutters>
            <TextField
              fullWidth
              variant='standard'
              id='value'
              label={`Credential ID`}
              value={credentialCredentialId ? credentialCredentialId : ''}
              onChange={(e) => setCredentialCredentialId(e.target.value)}
            />
          </ListItem>
          <Button
            type='submit'
            onClick={handleCredentialUpdate}
            color='primary'
            variant='contained'
            disabled={!credentialCredentialId || isLoading}>
            Update Resource
          </Button>
        </List>
      </form>
    </div>
  );
}
