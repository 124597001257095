import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import isEmail from 'validator/es/lib/isEmail';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function SelectUser(props) {
  const { appId, orgId, userId, setUser } = props;

  const allUsers = useSelector((state) => state[appId].users);
  const allMemberships = useSelector((state) => state[appId].memberships);
  const [availableUsers, setAvailableUsers] = useState([]);

  useEffect(() => {
    if (orgId) {
      const orgMemberships = filter(allMemberships, { org_id: orgId });
      setAvailableUsers(
        filter(allUsers, (user) => {
          return !filter(orgMemberships, { user_id: user.user_id }).length;
        })
      );
    } else {
      setAvailableUsers([]);
    }
  }, [allUsers, allMemberships, orgId]);

  const handleChange = (event, value, reason) => {
    if (typeof value === 'string' && value.startsWith('New User: ')) {
      const email = value.replace('New User: ', '');
      setUser({ email });
    } else if (value?.type_ === 'user') {
      setUser(value);
    }
  };

  return (
    <Autocomplete
      fullWidth
      data-lpignore='true'
      autoComplete
      autoHighlight
      autoSelect
      freeSolo
      onChange={handleChange}
      options={availableUsers}
      getOptionLabel={(option) => {
        const label = (() => {
          if (option?.name && option?.email) {
            return `${option.name} (${option.email})`;
          } else if (typeof option === 'string') {
            return option;
          }
          return '';
        })();

        return label;
      }}
      filterOptions={(options, params) => {
        const filtered = createFilterOptions()(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.email
        );
        if (inputValue !== '' && !isExisting && isEmail(inputValue)) {
          filtered.push(`New User: ${inputValue}`);
        }
        return filtered;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            disabled={!orgId}
            label='User'
            variant='standard'
            value={userId}
          />
        );
      }}
      slotProps={{
        listbox: { sx: { p: 0 } },
      }}
    />
  );
}

SelectUser.propTypes = {
  appId: PropTypes.string.isRequired,
  orgId: PropTypes.string,
  userId: PropTypes.string.isRequired,
  setUser: PropTypes.func.isRequired,
};

export default SelectUser;
