import React from 'react';
import isEmail from 'validator/es/lib/isEmail';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function SelectEnergyTracerUser(props) {
  const { users, userId, setUser } = props;

  const handleChange = (event, value, reason) => {
    if (typeof value === 'string' && value.startsWith('New User: ')) {
      const email = value.replace('New User: ', '');
      setUser({ email: email });
    } else if (value?.email) {
      setUser(value);
    }
  };

  return (
    <Autocomplete
      fullWidth
      data-lpignore='true'
      autoComplete
      autoHighlight
      autoSelect
      freeSolo
      onChange={handleChange}
      options={users}
      getOptionLabel={(option) => {
        const label = (() => {
          if (option?.name && option?.email) {
            return `${option.name} (${option.email})`;
          } else if (typeof option === 'string') {
            return option;
          }
          return '';
        })();

        return label;
      }}
      filterOptions={(options, params) => {
        const filtered = createFilterOptions()(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.email
        );
        if (inputValue !== '' && !isExisting && isEmail(inputValue)) {
          filtered.push(`New User: ${inputValue}`);
        }
        return filtered;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            disabled={false}
            label='User'
            variant='standard'
            value={userId}
          />
        );
      }}
      slotProps={{
        listbox: { sx: { p: 0 } },
      }}
    />
  );
}

export default SelectEnergyTracerUser;
