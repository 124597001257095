import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import orderBy from 'lodash/orderBy';

import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import { showLoading, hideLoading } from '../../../../store/app';
import { openConfirmDialog } from '../../../../store/dialogs';
import { toastr } from '../../../../components/CustomToast';
import BaseTable from '../../../../components/Table/BaseTable';
import HeaderEditCell from '../../../../components/Table/cells/HeaderEditCell';
import EditCell from '../../../../components/Table/cells/EditCell';
import { MembershipRoleTypeProvider } from '../../../../components/Table/providers';
import WebAPIClient, {
  errorResponseToastr,
} from '../../../../api/_energytracer';
import PortfolioOrganizationDialog from '../../../../components/Dialogs/energytracer/PortfolioOrganizationDialog';

export default function PortfolioOrganizations() {
  const dispatch = useDispatch();
  const [organizations, setOrganizations] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [open, setOpen] = useState(false);

  const [tableColumnExtensions] = useState([
    { columnName: 'active', align: 'center' },
  ]);
  const [integratedFilteringColumnExtensions] = useState([
    {
      columnName: 'organization_name',
      predicate: (value, filter) => {
        return value.toLowerCase().includes(filter.value.toLowerCase());
      },
    },
  ]);
  const [integratedSortingColumnExtensions] = useState([
    {
      columnName: 'organization_name',
      compare: (a, b) => {
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
      },
    },
  ]);
  const [columns] = useState([
    { title: 'Organization', name: 'organization_name', width: 0.4 },
    { title: 'Portfolio', name: 'portfolio_name', width: 0.4 },
    { title: 'Role', name: 'role', width: 0.15 },
  ]);

  const fetchData = useCallback(async () => {
    dispatch(showLoading());

    try {
      const data = await new WebAPIClient().GET('/portfolios');

      const portfolios = data.portfolios.filter(
        (portfolio) => portfolio.id !== -1
      );
      const organizations = data.organizations;

      let organization_lookup = {};
      organizations.forEach((org) => (organization_lookup[org.id] = org));

      let table_rows = [];
      portfolios.forEach((portfolio) => {
        const pos = portfolio.pos;
        Object.keys(portfolio.pos).forEach((po) => {
          let org = organization_lookup[po];
          table_rows.push({
            organization_id: po,
            organization_name: org.name,
            portfolio_id: portfolio.id,
            portfolio_name: portfolio.name,
            role: pos[po],
          });
        });
      });
      setPortfolios(orderBy(portfolios, 'name'));
      setOrganizations(orderBy(organizations, 'name'));
      setTableRows(table_rows);
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
    dispatch(hideLoading());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteClick = (membership) => {
    const message = <p>Are you sure you want to delete this membership?</p>;
    dispatch(
      openConfirmDialog({
        title: 'Delete Membership',
        message: message,
        onConfirm: () => deleteMembership(membership),
      })
    );
  };

  const deleteMembership = async (membership) => {
    dispatch(showLoading());
    try {
      await new WebAPIClient().DELETE(
        `portfolios/portfolio_organizations/portfolio/${membership.portfolio_id}/${membership.organization_id}`
      );
      setTableRows((prev) =>
        prev.filter(
          (data) =>
            !(
              data.portfolio_id === membership.portfolio_id &&
              data.organization_id === membership.organization_id
            )
        )
      );
      dispatch(hideLoading());
      toastr.success({ title: 'Membership deleted' });
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleEditClick = (membership, value) => {
    const message = (
      <div>
        <p>Are you sure you want to update this membership?</p>
      </div>
    );
    dispatch(
      openConfirmDialog({
        title: 'Update Membership',
        message: message,
        onConfirm: () => editMembership(membership, value),
      })
    );
  };

  const editMembership = async (membership, value) => {
    dispatch(showLoading());
    try {
      await new WebAPIClient().POST(
        `portfolios/portfolio_organizations/${membership.portfolio_id}/${membership.organization_id}/${value}`
      );
      setTableRows((prev) =>
        prev.map((data) => {
          if (
            data.portfolio_id === membership.portfolio_id &&
            data.organization_id === membership.organization_id
          )
            return { ...data, role: value };
          return data;
        })
      );
      dispatch(hideLoading());
      toastr.success({ title: 'Membership Updated' });
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ maxWidth: '100%', mt: 1 }}>
      <Grid
        size={{ xs: 12 }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
          px: 1,
        }}>
        <Card raised sx={{ maxWidth: 1000, width: '100%', px: 1 }}>
          <BaseTable
            minWidth={1000}
            rows={tableRows}
            columns={columns}
            tableColumnExtensions={tableColumnExtensions}
            editColumn={
              <TableEditColumn
                width={42}
                headerCellComponent={(props) => {
                  return (
                    <HeaderEditCell
                      disabled={false}
                      tooltipText='Create Membership'
                      handleClick={() => setOpen(true)}
                      {...props}
                    />
                  );
                }}
                cellComponent={(props) => (
                  <EditCell
                    disabled={false}
                    tooltipText='Delete Membership'
                    icon={['fal', 'trash']}
                    // eslint-disable-next-line
                    handleClick={() => handleDeleteClick(props.row)}
                    {...props}
                  />
                )}
              />
            }>
            <MembershipRoleTypeProvider
              for={['role']}
              appId={'energytracer'}
              handleClick={handleEditClick}
            />
            <EditingState />
            <FilteringState />
            <SortingState
              defaultSorting={[
                { columnName: 'organization_name', direction: 'asc' },
              ]}
            />
            <PagingState defaultCurrentPage={0} pageSize={10} />

            <IntegratedFiltering
              columnExtensions={integratedFilteringColumnExtensions}
            />
            <IntegratedSorting
              columnExtensions={integratedSortingColumnExtensions}
            />
            <IntegratedPaging />
          </BaseTable>
        </Card>
        <PortfolioOrganizationDialog
          portfolios={portfolios}
          organizations={organizations}
          setTableRows={setTableRows}
          open={open}
          setOpen={setOpen}
        />
      </Grid>
    </Grid>
  );
}
