import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import {
  current,
  today,
  yesterday,
  lastMonth,
  lastWeek,
  thisMonth,
  thisWeek,
} from '../../../../../helpers/date';
import { DATA_FEEDS } from './Chart';
import SelectMetrics from '../../../../../components/Selectors/SelectMetrics';
import SelectTimezone from '../../../../../components/Selectors/SelectTimezone';
import SelectRange from '../../../../../components/Selectors/SelectRange';

export const RANGES = [
  {
    key: 0,
    label: 'Current',
    func: current,
  },
  {
    key: 1,
    label: 'Today',
    func: today,
  },
  {
    key: 2,
    label: 'Yesterday',
    func: yesterday,
  },
  {
    key: 3,
    label: 'This Week',
    func: thisWeek,
  },
  {
    key: 4,
    label: 'Last Week',
    func: lastWeek,
  },
  {
    key: 5,
    label: 'This Month',
    func: thisMonth,
  },
  {
    key: 6,
    label: 'Last Month',
    func: lastMonth,
  },
  { key: 7, label: 'Custom Range' },
];
function ModelingChartToolbar(props) {
  const {
    selectedMetrics,
    setSelectedMetrics,
    range,
    setRange,
    timezone,
    setTimezone,
    fetchChartData,
  } = props;

  const handleRangeSelect = (newRange, fetch = false) => {
    setRange(newRange);
    if (fetch) fetchChartData(newRange);
  };

  return (
    <Grid size={{ xs: 12, md: 6 }}>
      <Card raised sx={{ py: 0.5, px: 1 }}>
        <Stack
          direction='row'
          justifyContent='space-around'
          alignItems='center'
          spacing={1}
          sx={{ px: 1 }}
          divider={<Divider orientation='vertical' flexItem sx={{ my: 1 }} />}>
          <SelectMetrics
            metrics={Object.keys(DATA_FEEDS)}
            selectedMetrics={selectedMetrics}
            setSelectedMetrics={setSelectedMetrics}
          />
          <SelectRange
            ranges={RANGES}
            range={range}
            defaultRange={RANGES[5]}
            setRange={handleRangeSelect}
            timezone={timezone}
          />
          <SelectTimezone
            selectedTimezone={timezone}
            setSelectedTimezone={setTimezone}
          />
          <Tooltip title='fetch data' placement='top'>
            <span>
              <IconButton onClick={() => fetchChartData(range)}>
                <FontAwesomeIcon icon={['fal', 'cloud-download']} />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </Card>
    </Grid>
  );
}

ModelingChartToolbar.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object),
  selectedMetrics: PropTypes.array.isRequired,
  setSelectedMetrics: PropTypes.func.isRequired,
  range: PropTypes.object.isRequired,
  setRange: PropTypes.func.isRequired,
  timezone: PropTypes.object.isRequired,
  setTimezone: PropTypes.func.isRequired,
  fetchChartData: PropTypes.func.isRequired,
};

export default ModelingChartToolbar;
