import { createAsyncThunk } from '@reduxjs/toolkit';
import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { DEMAND_APP_ID } from '.';
import { toastr } from '../../components/CustomToast';
const postDemandMembership = createAsyncThunk(
  'demand/postDemandMembership',
  async (newMembership, { dispatch, getState }) => {
    const stateMemberships = getState().demand.memberships;
    const stateUsers = getState().demand.users;
    let memberships = cloneDeep(stateMemberships);
    let users = cloneDeep(stateUsers);
    try {
      dispatch(showLoading());
      const { membership, user } = await new WebAPIClient(DEMAND_APP_ID).POST(
        '/resource/memberships',
        newMembership
      );
      remove(users, { user_id: user.user_id });
      memberships = concat(memberships, membership);
      users = concat(users, user);

      toastr.success({ title: 'Membership created' });
      return { memberships };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putDemandMembership = createAsyncThunk(
  'demand/putDemandMembership',
  async (updatedMembership, { dispatch, getState }) => {
    const stateMemberships = getState().demand.memberships;
    let memberships = cloneDeep(stateMemberships);
    try {
      dispatch(showLoading());
      const membership = await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/memberships/${updatedMembership.org_id}/${updatedMembership.user_id}`,
        updatedMembership
      );

      remove(memberships, {
        org_id: membership.org_id,
        user_id: membership.user_id,
      });
      memberships = concat(memberships, membership);

      toastr.success({ title: 'Membership updated' });
      return { memberships };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteDemandMembership = createAsyncThunk(
  'demand/deleteDemandMembership',
  async (deletedMembership, { dispatch, getState }) => {
    const stateMemberships = getState().demand.memberships;
    const stateUsers = getState().demand.users;
    let memberships = cloneDeep(stateMemberships);
    let users = cloneDeep(stateUsers);
    try {
      dispatch(showLoading());
      const user = await new WebAPIClient(DEMAND_APP_ID).DELETE(
        `/resource/memberships/${deletedMembership.org_id}/${deletedMembership.user_id}`
      );

      remove(memberships, {
        org_id: deletedMembership.org_id,
        user_id: deletedMembership.user_id,
      });
      remove(users, { user_id: user.user_id });
      users = concat(users, user);

      toastr.success({ title: 'Membership deleted' });
      return { memberships, users };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { postDemandMembership, putDemandMembership, deleteDemandMembership };
