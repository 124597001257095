import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { openConfirmDialog } from '../../../../../store/dialogs';
import { putNiagara } from '../../../../../store/demand';
import NiagaraDevicesTable from './NiagaraDevicesTable';
import ClipboardField from '../../../../../components/Buttons/ClipboardField';

const defaultState = {
  org_id: '',
  niagara_id: '',
  active: false,
  devices: [],
};

export default function NiagaraGridItems(props) {
  const { organization } = props;
  const dispatch = useDispatch();
  const niagaras = useSelector((state) => state.demand.niagaras);
  const [niagara, setNiagara] = useState(defaultState);

  useEffect(() => {
    const _niagara = niagaras.find(
      (niagara) => niagara.org_id === organization.org_id
    );
    if (_niagara) {
      setNiagara(_niagara);
    } else {
      setNiagara(defaultState);
    }
  }, [niagaras, organization.org_id]);

  const handleChange = (e) => {
    const active = e.target.checked;
    const message = (
      <p>
        Are you sure you want to{' '}
        <strong>{active ? 'activate' : 'deactivate'}</strong> this Niagara
        instance?
      </p>
    );
    dispatch(
      openConfirmDialog({
        title: active ? 'Activate Niagara' : 'Deactivate Niagara',
        message,
        onConfirm: () =>
          dispatch(
            putNiagara({
              active,
              niagara_id: niagara.niagara_id,
              org_id: niagara.org_id,
            })
          ),
      })
    );
  };

  return (
    <>
      <Grid
        size={{ xs: 12 }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mx: 1,
        }}>
        <Card raised sx={{ maxWidth: 400, width: '100%', px: 2, py: 1 }}>
          <CardHeader title='Niagara Instance' sx={{ pt: 0.5, pb: 0 }} />
          <List disablePadding>
            {/* NIAGARA ID */}
            <ListItem disableGutters>
              <ClipboardField
                id='niagara_id'
                value={niagara.niagara_id}
                label='Niagara ID'
              />
            </ListItem>
            {/* ACTIVE */}
            <ListItem disableGutters>
              <FormControlLabel
                control={
                  <Checkbox
                    id='active'
                    checked={niagara.active}
                    onChange={handleChange}
                  />
                }
                label='Active'
              />
            </ListItem>
          </List>
        </Card>
      </Grid>
      <NiagaraDevicesTable niagara={niagara} />
    </>
  );
}
