import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid2';

import { openConfirmDialog } from '../../../../../store/dialogs';
import {
  deleteSolarInverter,
  putSolarInverter,
} from '../../../../../store/solar';

import InverterForm from '../../../../../components/Forms/solar/InverterForm';

export default function InverterPage(props) {
  const { inverter: stateInverter } = props;
  const dispatch = useDispatch();
  const [inverter, setInverter] = useState(stateInverter);

  const handleChange = (e) => {
    const id = e.target.id || e.target.name;
    const value = e.target.value;
    setInverter((prevInverter) => ({ ...prevInverter, [id]: value }));
  };

  const handleSubmit = () => {
    dispatch(putSolarInverter(inverter));
  };

  const handleDelete = () => {
    dispatch(
      openConfirmDialog({
        title: 'Delete Inverter',
        message: <p>Are you sure you want to delete this inverter?</p>,
        onConfirm: () => dispatch(deleteSolarInverter(inverter)),
      })
    );
  };

  return (
    <Grid
      size={{ xs: 12 }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '100vw',
        px: 1,
      }}>
      <Card raised sx={{ width: 400, px: 2, py: 1 }}>
        <InverterForm
          mode='edit'
          inverter={inverter}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          expanded={true}
        />
        <CardActions sx={{ justifyContent: 'space-between' }}>
          <Button variant='outlined' color='error' onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='contained' onClick={handleSubmit}>
            Save
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}
