import React from 'react';
import Grid from '@mui/material/Grid2';

import LightLevelGrid from './LightLevelGrid';
import GridcapGrid from './GridcapGrid';
import HubGrid from './HubGrid';
import EnergyTracerGrid from './EnergyTracerGrid';

export default function Home() {
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ maxWidth: '100%', mt: 2, mb: 5 }}>
      <LightLevelGrid />
      <GridcapGrid />
      <HubGrid />
      <EnergyTracerGrid />
    </Grid>
  );
}
