import { createAsyncThunk } from '@reduxjs/toolkit';
import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { DEMAND_APP_ID } from '.';
import { toastr } from '../../components/CustomToast';

const postDemandOrganization = createAsyncThunk(
  'demand/postDemandOrganization',
  async (newOrganization, { dispatch, getState }) => {
    const stateOrganizations = getState().demand.organizations;
    const stateLicenses = getState().demand.licenses;
    const stateNiagaras = getState().demand.niagaras;

    let organizations = cloneDeep(stateOrganizations);
    let licenses = cloneDeep(stateLicenses);
    let niagaras = cloneDeep(stateNiagaras);
    try {
      dispatch(showLoading());
      const {
        organization,
        licenses: newLicenses,
        niagara,
      } = await new WebAPIClient(DEMAND_APP_ID).POST(
        '/resource/organizations',
        newOrganization
      );

      if (newLicenses.length > 0) {
        licenses = [...licenses, ...newLicenses];
      }

      if (niagara) {
        niagaras = concat(niagaras, niagara);
      }

      toastr.success({
        title: 'Organization created',
        message: organization.name,
      });
      return {
        organizations: [...organizations, organization],
        licenses,
        niagaras,
        newOrg: organization,
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putDemandOrganization = createAsyncThunk(
  'demand/putDemandOrganization',
  async (updatedOrganization, { dispatch, getState }) => {
    const stateOrganizations = getState().demand.organizations;
    let organizations = cloneDeep(stateOrganizations);
    try {
      dispatch(showLoading());

      const organization = await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/organizations/${updatedOrganization.org_id}`,
        updatedOrganization
      );

      remove(organizations, { org_id: organization.org_id });
      organizations = concat(organizations, organization);

      toastr.success({ title: 'Organization updated' });
      return { organizations };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putDemandLicense = createAsyncThunk(
  'demand/putDemandLicense',
  async (license, { getState, requestId, dispatch }) => {
    try {
      const { currentRequestId, loading, licenses } = getState().demand;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const updatedLicense = await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/licenses/${license.org_id}/${license.name}`,
        license
      );

      let _licenses = cloneDeep(licenses);
      remove(_licenses, (license) => {
        return (
          license.org_id === updatedLicense.org_id &&
          license.name === updatedLicense.name
        );
      });

      toastr.success({ title: 'License updated' });
      return { licenses: concat(_licenses, updatedLicense) };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { postDemandOrganization, putDemandOrganization, putDemandLicense };
