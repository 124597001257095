import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import { ROUTES } from '../../helpers/constants';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { DEMAND_APP_ID } from '.';
import { toastr } from '../../components/CustomToast';
const authenticateDemandUser = createAsyncThunk(
  'demand/authenticateDemandUser',
  async (_, { dispatch }) => {
    try {
      dispatch(showLoading());
      const user = await new WebAPIClient(DEMAND_APP_ID).GET(
        '/resource/admin/init_auth'
      );
      return { user };
    } catch (err) {
      console.warn('Demand Authentication Error');
      throw new Error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getDemandResources = createAsyncThunk(
  'demand/getDemandResources',
  async (_, { dispatch }) => {
    try {
      dispatch(showLoading());

      const webClient = new WebAPIClient(DEMAND_APP_ID);

      const responses = await Promise.all([
        webClient.GET('/resource/admin/alarms'),
        webClient.GET('/resource/admin/memberships'),
        webClient.GET('/resource/admin/niagaras'),
        webClient.GET('/resource/admin/organizations'),
        webClient.GET('/resource/admin/portfolio_memberships'),
        webClient.GET('/resource/admin/sites'),
        webClient.GET('/resource/admin/licenses'),
        webClient.GET('/resource/admin/meters'),
        webClient.GET('/resource/admin/users'),
        webClient.GET('/resource/admin/rtos'),
      ]);
      const alarms = responses[0];
      const memberships = responses[1];
      const niagaras = responses[2];
      const organizations = responses[3];
      const portfolioMemberships = responses[4];
      const sites = responses[5];
      const licenses = responses[6];
      const meters = responses[7];
      const users = responses[8];
      const rtos = responses[9];

      return {
        alarms,
        memberships,
        niagaras,
        organizations,
        portfolioMemberships,
        sites,
        licenses,
        meters,
        users,
        rtos,
      };
    } catch (err) {
      errorResponseToastr(err);
      dispatch(push(ROUTES.LANDING.path));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putRTO = createAsyncThunk(
  'demand/putRTO',
  async (rto, { dispatch, getState }) => {
    const rtos = getState().demand.rtos;
    let _rtos = cloneDeep(rtos);

    try {
      dispatch(showLoading());
      let _rto = await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/rtos/${rto.rto_id}`,
        rto
      );

      remove(_rtos, { rto_id: get(rto, 'rto_id') });
      _rtos = concat(_rtos, _rto);

      toastr.success({
        title: 'RTO updated',
        message: _rto.name,
      });

      return { rtos: _rtos };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putNiagara = createAsyncThunk(
  'demand/putNiagara',
  async ({ active, devices, niagara_id, org_id }, { dispatch, getState }) => {
    const niagaras = getState().demand.niagaras;
    let _niagaras = cloneDeep(niagaras);

    try {
      dispatch(showLoading());
      let updatedNiagara = await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/niagaras/${niagara_id}`,
        {
          active,
          devices,
          niagara_id,
          org_id,
        }
      );

      remove(_niagaras, { niagara_id });
      _niagaras = concat(_niagaras, updatedNiagara);

      toastr.success({
        title: 'Niagara updated',
        message: updatedNiagara.name,
      });
      return { niagaras: _niagaras };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { authenticateDemandUser, getDemandResources, putRTO, putNiagara };
