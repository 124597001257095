import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import map from 'lodash/map';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { DEMAND_APP_ID } from '.';
import { ROUTES } from '../../helpers/constants';
import { toastr } from '../../components/CustomToast';
const postDemandMeter = createAsyncThunk(
  'demand/postDemandMeter',
  async (newMeter, { dispatch, getState }) => {
    const stateMeters = getState().demand.meters;
    let meters = cloneDeep(stateMeters);
    try {
      dispatch(showLoading());
      const meter = await new WebAPIClient(DEMAND_APP_ID).POST(
        '/resource/meters',
        newMeter
      );

      toastr.success({ title: 'Meter created' });

      return {
        meters: [...meters, meter],
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putDemandMeter = createAsyncThunk(
  'demand/putDemandMeter',
  async (updatedMeter, { dispatch, getState }) => {
    const stateMeters = getState().demand.meters;
    let meters = cloneDeep(stateMeters);
    try {
      dispatch(showLoading());

      const meter = await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/meters/${updatedMeter.org_id}/${updatedMeter.meter_id}`,
        updatedMeter
      );
      remove(meters, {
        org_id: updatedMeter.org_id,
        meter_id: updatedMeter.meter_id,
      });
      meters = concat(meters, meter);

      toastr.success({ title: 'Meter updated' });
      return { meters };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteDemandMeter = createAsyncThunk(
  'demand/deleteDemandMeter',
  async (deletedMeter, { dispatch, getState }) => {
    const stateMeters = getState().demand.meters;
    let meters = cloneDeep(stateMeters);
    try {
      dispatch(showLoading());
      await new WebAPIClient(DEMAND_APP_ID).DELETE(
        `/resource/meters/${deletedMeter.org_id}/${deletedMeter.meter_id}`
      );

      remove(meters, {
        org_id: deletedMeter.org_id,
        meter_id: deletedMeter.meter_id,
      });

      toastr.success({ title: 'Meter deleted' });
      return { meters };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
      dispatch(push(ROUTES.GRIDCAP.path));
    }
  }
);

const putMeterShedValues = createAsyncThunk(
  'demand/putMeterShedValues',
  async ({ shed, meters }, { dispatch, getState }) => {
    try {
      const allMeters = getState().demand.meters;

      meters = map(meters, (meter) => {
        return {
          ...meter,
          shed: !(shed === true || shed === false) ? null : Boolean(shed),
        };
      });

      let _meters = cloneDeep(allMeters);

      dispatch(showLoading());
      const updatedMeters = await Promise.all(
        map(meters, (meter) => {
          return new WebAPIClient(DEMAND_APP_ID).PUT(
            `/resource/meters/${meter.org_id}/${meter.meter_id}`,
            meter
          );
        })
      );

      for (let meter of updatedMeters) {
        remove(_meters, {
          org_id: meter.org_id,
          meter_id: meter.meter_id,
        });
        _meters = concat(_meters, meter);
      }

      toastr.success({ title: 'Meter shed values updated' });
      return { meters: _meters };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  postDemandMeter,
  putDemandMeter,
  deleteDemandMeter,
  putMeterShedValues,
};
