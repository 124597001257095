import React, { useEffect, useState } from 'react';

import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';

import GroupContainer from './GroupContainer';
import WidgetLoader from '../Loaders/WidgetLoader';
import { StyledSubtab, StyledSubtabs } from '../StyledSubtabs';

const COLORS = [
  'blue',
  'lightblue',
  'green',
  'grey',
  'orange',
  'purple',
  'yellow',
];

export default function EnergyTracerKPIsPage(props) {
  const { metrics, fetchData } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(Object.keys(metrics)[0]);
  const [metricCounts, setMetricCounts] = useState(null);

  const [barData, setBarData] = useState('');

  const fetchChartData = async () => {
    setLoading(true);
    try {
      const payload = await fetchData();
      setMetricCounts(payload);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (metricCounts === null) {
      fetchChartData();
    }
    // eslint-disable-next-line
  }, []);

  const handleSelect = (e, groupName) => {
    setSelectedGroup(groupName);
  };

  useEffect(() => {
    if (metricCounts && metricCounts.yearly_resources) {
      let temp_data = [];
      metricCounts.yearly_resources.months.forEach((month, idx) => {
        temp_data.push({
          name: month + ' ' + metricCounts.yearly_resources.years[idx],
          month: month,
          Organizations: metricCounts.yearly_resources.organizations[idx],
          Buildings: metricCounts.yearly_resources.buildings[idx],
          Accounts: metricCounts.yearly_resources.accounts[idx],
        });
      });
      setBarData(temp_data);
    }
  }, [metricCounts]);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ maxWidth: '100%', px: isMobile ? 0 : 1, py: isMobile ? 0 : 1 }}>
      {isMobile ? (
        <>
          <Grid size={{ xs: 12 }} sx={{ mx: 1 }}>
            <StyledSubtabs
              variant='fullWidth'
              sx={{ pb: 1 }}
              value={selectedGroup}
              onChange={handleSelect}>
              {Object.keys(metrics).map((metric) => (
                <StyledSubtab key={metric} label={metric} value={metric} />
              ))}
            </StyledSubtabs>
          </Grid>
          <Grid
            size={{ xs: 12 }}
            sx={{ mt: 0, display: 'flex', justifyContent: 'center' }}>
            <Card
              raised
              sx={{
                py: 1,
                px: 1,
                mx: 1,
                maxWidth: 600,
                width: '100%',
              }}>
              <WidgetLoader loading={loading} />
              <GroupContainer
                metrics={metrics[selectedGroup]}
                metricCounts={metricCounts}
              />
            </Card>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            size={{ xs: 4 }}
            flexDirection='column'
            display='flex'
            justifyContent='flex-start'>
            <Card
              raised
              sx={{
                py: 1,
                px: 1,
                mb: 1,
                width: '100%',
              }}>
              <GroupContainer
                metrics={metrics.users}
                metricCounts={metricCounts}
              />
            </Card>
            <Card
              raised
              sx={{
                py: 1,
                px: 1,
                width: '100%',
              }}>
              <GroupContainer
                metrics={metrics.resources}
                metricCounts={metricCounts}
              />
            </Card>
          </Grid>
          <Grid size={{ xs: 4 }} display='flex' justifyContent='center'>
            <Card raised sx={{ py: 1, px: 1, width: '100%' }}>
              <WidgetLoader loading={loading} />
              <GroupContainer
                metrics={metrics.bills}
                metricCounts={metricCounts}
              />
            </Card>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        size={{ xs: 12 }}
        sx={{ mx: 1 }}
        display='flex'
        justifyContent='center'>
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.background.paper,
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[10],
            p: 1,
            width: '100%',
            maxWidth: 1200,
          }}>
          <WidgetLoader loading={loading} />
          <ResponsiveContainer
            key='device-kpis-chart-responsive-container'
            width='100%'
            height={400}>
            <BarChart width='100%' height={400} data={barData}>
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey={'Organizations'}
                fill={theme.palette.veregy_colors[COLORS[0]]}
              />
              <Bar
                dataKey={'Buildings'}
                fill={theme.palette.veregy_colors[COLORS[1]]}
              />
              <Bar
                dataKey={'Accounts'}
                fill={theme.palette.veregy_colors[COLORS[2]]}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Grid>
    </Grid>
  );
}
