import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postSolarMeter } from '../../../store/solar';
import { toastr } from '../../CustomToast';
import MeterForm, { defaultMeterState } from '../../Forms/solar/MeterForm';
import BaseDialog from '../BaseDialog';

function MeterDialog(props) {
  const { open, closeDialog, orgId, siteId, loggerId } = props;
  const dispatch = useDispatch();

  const meters = useSelector((state) => state.solar.meters);

  const [parentIndex, setParentIndex] = useState(1);
  const [meter, setMeter] = useState(defaultMeterState);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const loggerMeters = meters.filter((meter) => meter.logger_id === loggerId);
    setParentIndex(loggerMeters.length + 1);
  }, [orgId, siteId, loggerId, meters]);

  useEffect(() => {
    setMeter((prevMeter) => ({
      ...prevMeter,
      org_id: orgId,
      site_id: siteId,
      logger_id: loggerId,
      parent_index: parentIndex,
    }));
  }, [orgId, siteId, loggerId, parentIndex]);

  const handleChange = (e) => {
    const id = e.target.id || e.target.name;
    const value = e.target.value;
    setMeter((prevMeter) => ({ ...prevMeter, [id]: value }));
  };

  useEffect(() => {
    if (meter.name.length === 0) {
      setMessage('Meter name required');
    } else {
      setMessage('');
    }
  }, [meter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message) {
      toastr.warning({ title: message });
    } else {
      dispatch(postSolarMeter(meter));
      handleClose();
    }
  };

  const handleClose = () => {
    closeDialog();
    setMeter({
      ...defaultMeterState,
      org_id: orgId,
      site_id: siteId,
      logger_id: loggerId,
      parent_index: parentIndex,
    });
  };

  const id = open ? 'create-meter-dialog' : '';
  return (
    <BaseDialog
      id={id}
      disabled={message.length > 0}
      submitText='Create'
      title='Create Meter'
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <MeterForm
        mode='create'
        meter={meter}
        setMeter={setMeter}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </BaseDialog>
  );
}

export default MeterDialog;
