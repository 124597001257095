import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { SOLAR_APP_ID } from '.';
import { ROUTES } from '../../helpers/constants';
import { toastr } from '../../components/CustomToast';

const postSolarLogger = createAsyncThunk(
  'solar/postSolarLogger',
  async (newLogger, { dispatch, getState }) => {
    const stateLoggers = getState().solar.loggers;
    let loggers = cloneDeep(stateLoggers);
    try {
      dispatch(showLoading());
      const { logger } = await new WebAPIClient(SOLAR_APP_ID).POST(
        '/resource/loggers',
        newLogger
      );

      toastr.success({ title: 'Logger created' });
      return {
        loggers: [...loggers, logger],
        newLogger: logger,
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putSolarLogger = createAsyncThunk(
  'solar/putSolarLogger',
  async (updatedLogger, { dispatch, getState }) => {
    const stateLoggers = getState().solar.loggers;
    let loggers = cloneDeep(stateLoggers);
    try {
      dispatch(showLoading());

      const logger = await new WebAPIClient(SOLAR_APP_ID).PUT(
        `/resource/loggers/${updatedLogger.org_id}/${updatedLogger.logger_id}`,
        updatedLogger
      );
      remove(loggers, {
        org_id: updatedLogger.org_id,
        logger_id: updatedLogger.logger_id,
      });
      loggers = concat(loggers, logger);

      toastr.success({ title: 'Logger updated' });
      return { loggers };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteSolarLogger = createAsyncThunk(
  'solar/deleteSolarLogger',
  async (deletedLogger, { dispatch, getState }) => {
    const {
      loggers: stateLoggers,
      meters: stateMeters,
      inverters: stateInverters,
      sensors: stateSensors,
    } = getState().solar;
    let loggers = cloneDeep(stateLoggers);
    let meters = cloneDeep(stateMeters);
    let inverters = cloneDeep(stateInverters);
    let sensors = cloneDeep(stateSensors);
    try {
      dispatch(showLoading());
      await new WebAPIClient(SOLAR_APP_ID).DELETE(
        `/resource/loggers/${deletedLogger.org_id}/${deletedLogger.logger_id}`
      );

      const loggerMeterIds = meters
        .filter((meter) => meter.logger_id === deletedLogger.logger_id)
        .map((meter) => meter.meter_id);
      const loggerInverterIds = inverters
        .filter((inverter) => loggerMeterIds.includes(inverter.meter_id))
        .map((inverter) => inverter.inverter_id);
      const loggerSensorIds = sensors
        .filter((sensor) => loggerMeterIds.includes(sensor.meter_id))
        .map((sensor) => sensor.sensor_id);

      remove(loggers, {
        org_id: deletedLogger.org_id,
        logger_id: deletedLogger.logger_id,
      });
      remove(meters, (meter) => loggerMeterIds.includes(meter.meter_id));
      remove(inverters, (inverter) =>
        loggerInverterIds.includes(inverter.inverter_id)
      );
      remove(sensors, (sensor) => loggerSensorIds.includes(sensor.sensor_id));

      toastr.success({ title: 'Logger deleted' });
      return { loggers, meters, inverters, sensors };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
      dispatch(push(ROUTES.LIGHTLEVEL.path));
    }
  }
);

export { postSolarLogger, putSolarLogger, deleteSolarLogger };
