import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import startCase from 'lodash/startCase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import {
  postSolarInverter,
  postSolarLogger,
  postSolarMeter,
  postSolarSensor,
} from '../../../../store/solar';
import { TextFieldListItem } from '../../../List/TextFieldListItem';
import { defaultLoggerState } from '../../../Forms/solar/LoggerForm';
import useOrganization from '../../../../store/hooks/useOrganization';
import useSite from '../../../../store/hooks/useSite';

const STEPS = [
  { message: 'Ready to create the following resources' },
  { message: 'Creating Logger...' },
  { message: 'Creating Meters...' },
  { message: 'Creating Sensors...' },
  { message: 'Creating Inverters...' },
  { message: 'Resources successfully created' },
];

const ResourceListItem = (props) => {
  const { resource, icon } = props;

  const creationState = resource.creationState;

  const [stateIcon, color] = (() => {
    if (!creationState) return [icon, 'inherit'];
    if (creationState === 'ready') return ['minus', 'inherit'];
    if (creationState === 'loading') return ['spinner', 'primary'];
    if (creationState === 'done') return ['check-circle', 'success'];
  })();

  const parentIndex = resource.parent_index;
  let label = startCase(resource.type_);
  if (parentIndex !== undefined) {
    label = `${label} ${parentIndex}`;
  }

  return (
    <TextFieldListItem
      label={label}
      value={get(resource, 'name', '')}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
        endAdornment: stateIcon && (
          <InputAdornment position='end'>
            <Icon color={color}>
              <FontAwesomeIcon icon={['fal', stateIcon]} />
            </Icon>
          </InputAdornment>
        ),
      }}
    />
  );
};

function ConfirmCreation(props) {
  const {
    handleClose,
    orgId,
    siteId,
    logger: _logger,
    meters: _meters,
    inverters: _inverters,
    sensors: _sensors,
  } = props;

  const dispatch = useDispatch();
  const organization = useOrganization(orgId, 'solar');
  const site = useSite(siteId, 'solar');

  const [createStep, setCreateStep] = useState(0);
  const [logger, setLogger] = useState(defaultLoggerState);
  const [meters, setMeters] = useState([]);
  const [inverters, setInverters] = useState([]);
  const [sensors, setSensors] = useState([]);

  useEffect(() => {
    setLogger({ ..._logger, creationState: 'ready' });
  }, [_logger]);

  useEffect(() => {
    setMeters([
      ..._meters.map((meter) => ({ ...meter, creationState: 'ready' })),
    ]);
  }, [_meters]);

  useEffect(() => {
    setInverters([
      ..._inverters.map((inverter) => ({
        ...inverter,
        creationState: 'ready',
      })),
    ]);
  }, [_inverters]);

  useEffect(() => {
    setSensors([
      ..._sensors.map((sensor) => ({ ...sensor, creationState: 'ready' })),
    ]);
  }, [_sensors]);

  useEffect(() => {
    const postLogger = async () => {
      setLogger((prevLogger) => ({ ...prevLogger, creationState: 'loading' }));
      const {
        payload: { newLogger },
      } = await dispatch(
        postSolarLogger({ ..._logger, org_id: orgId, site_id: siteId })
      );
      setLogger({ ...newLogger, creationState: 'done' });
      setCreateStep(createStep + 1);
    };
    if (createStep === 1) {
      postLogger();
    }
  }, [createStep, _logger, dispatch, orgId, siteId]);

  useEffect(() => {
    const postMeters = async () => {
      setMeters((prevMeters) =>
        prevMeters.map((meter) => ({ ...meter, creationState: 'loading' }))
      );
      let newMeters = [];
      for (const meter of _meters) {
        const {
          payload: { newMeter },
        } = await dispatch(
          postSolarMeter({
            ...meter,
            org_id: orgId,
            site_id: siteId,
            logger_id: logger.logger_id,
          })
        );
        newMeters.push(newMeter);
      }
      setMeters(
        newMeters.map((meter) => ({ ...meter, creationState: 'done' }))
      );
      setCreateStep(createStep + 1);
    };
    if (createStep === 2) {
      postMeters();
    }
  }, [createStep, logger, _meters, dispatch, orgId, siteId]);

  useEffect(() => {
    const postSensors = async () => {
      const meter1 = find(meters, { parent_index: 1 });
      setSensors((prevSensors) =>
        prevSensors.map((sensor) => ({ ...sensor, creationState: 'loading' }))
      );
      let newSensors = [];
      for (const sensor of _sensors) {
        const {
          payload: { newSensor },
        } = await dispatch(
          postSolarSensor({
            ...sensor,
            org_id: orgId,
            meter_id: meter1.meter_id,
          })
        );
        newSensors.push(newSensor);
      }
      setSensors(
        newSensors.map((sensor) => ({ ...sensor, creationState: 'done' }))
      );
      setCreateStep(createStep + 1);
    };
    if (createStep === 3) {
      postSensors();
    }
  }, [createStep, meters, _sensors, dispatch, orgId, siteId]);

  useEffect(() => {
    const postInverters = async () => {
      const meter1 = find(meters, { parent_index: 1 });
      setInverters((prevInverters) =>
        prevInverters.map((inverter) => ({
          ...inverter,
          creationState: 'loading',
        }))
      );
      let newInverters = [];
      for (const inverter of _inverters) {
        const {
          payload: { newInverter },
        } = await dispatch(
          postSolarInverter({
            ...inverter,
            org_id: orgId,
            meter_id: meter1.meter_id,
          })
        );
        newInverters.push(newInverter);
      }
      setInverters(
        newInverters.map((inverter) => ({ ...inverter, creationState: 'done' }))
      );
      setCreateStep(createStep + 1);
    };
    if (createStep === 4) {
      postInverters();
    }
  }, [createStep, meters, _inverters, dispatch, orgId, siteId]);

  const handleButtonClick = () => {
    if (createStep === 0) {
      setCreateStep(1);
    }
    if (createStep === 5) {
      handleClose();
    }
  };

  const message = STEPS[createStep].message;
  return (
    <List disablePadding>
      <ResourceListItem resource={organization} icon='buildings' />
      <ResourceListItem resource={site} icon='building' />
      <Divider />
      <ListItem disableGutters>
        <ListItemText
          primary={message}
          slotProps={{
            primary: { align: 'center' },
          }}
        />
      </ListItem>
      <ResourceListItem resource={logger} />
      {meters.map((meter, idx) => (
        <ResourceListItem key={`meter-${idx}`} resource={meter} />
      ))}
      {sensors.map((sensor, idx) => (
        <ResourceListItem key={`sensor-${idx}`} resource={sensor} />
      ))}
      {inverters.map((inverter, idx) => (
        <ResourceListItem key={`inverter-${idx}`} resource={inverter} />
      ))}
      <ListItem disableGutters sx={{ justifyContent: 'center' }}>
        <Button
          disabled={[1, 2, 3, 4].includes(createStep)}
          variant='contained'
          sx={{ width: '50%' }}
          onClick={handleButtonClick}>
          {createStep === 5 ? 'Close' : 'Create'}
        </Button>
      </ListItem>
    </List>
  );
}

export default ConfirmCreation;
