import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';

import { thunk as thunkMiddleware } from 'redux-thunk';

import app from './app';
import cognito from './cognito';
import costs from './costs';
import dialogs from './dialogs';
import user from './user';
import demand from './demand';
import solar from './solar';
import management from './management';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
  reducer: combineReducers({
    app,
    cognito,
    costs,
    dialogs,
    router: routerReducer,
    user,
    solar,
    demand,
    management,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(routerMiddleware, thunkMiddleware),
});

export const history = createReduxHistory(store);
