import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { numberToCurrency2 } from '../../../../helpers/currency';
import { StyledTd } from '../../../../components/charts/tooltips/BaseTooltip';

const findMetricLabel = (name) => {
  const regex = new RegExp(`(amazon-|aws-|app-)`, 'g');
  let metric = name.replace(regex, '');
  if (metric === 'app') return '[none]';
  return metric;
};

function CostsTooltip({ active, payload, label, granularity }) {
  const dayjsFormat = granularity === 'DAILY' ? 'MMMM Do' : 'MMMM YYYY';

  const str = dayjs.unix(label).tz('America/New_York').format(dayjsFormat);
  if (active && payload && payload.length) {
    return (
      <Card raised sx={{ width: '20rem', p: 1 }}>
        {label && (
          <Typography variant='body1' align='center'>
            {str}
          </Typography>
        )}
        <table style={{ width: '100%' }}>
          <tbody>
            <tr style={{ borderBottom: '1px solid #000' }}>
              <StyledTd borderBottom>Total</StyledTd>
              <StyledTd align='right' borderBottom>
                {numberToCurrency2.format(
                  reduce(
                    payload,
                    (acc, resource) => acc + get(resource, 'value', 0),
                    0
                  )
                )}
              </StyledTd>
            </tr>
            {map(payload, (resource) => {
              const label = findMetricLabel(resource?.name);
              const color = resource?.color;
              if (!resource) return null;
              if (label === 'total') return null;
              return (
                <tr key={resource?.dataKey}>
                  <StyledTd color={color} hideOverflow>
                    {label}
                  </StyledTd>
                  <StyledTd color={color} align='right'>
                    {numberToCurrency2.format(resource?.value)}
                  </StyledTd>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }

  return null;
}

CostsTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
};

export default CostsTooltip;
