import React from 'react';

import Grid from '@mui/material/Grid2';
import Costs from './Costs';

export default function AWS() {
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ maxWidth: '100%' }}>
      <Costs />
    </Grid>
  );
}
