import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import { calculateAverageAppCost } from '../../../helpers';
import { numberToCurrency2 } from '../../../helpers/currency';
import IconImg from './IconImg';
import DataCard from './DataCard';
import SwipeableContainer from '../../../components/SwipeableContainer';

import WebAPIClient from '../../../api/_energytracer';

const APP_ID = 'energytracer';

export default function EnergyTracerGrid() {
  const theme = useTheme();
  const { initial: costs, loading: costsLoading } = useSelector(
    (state) => state.costs
  );
  const [avgCost, setAvgCost] = useState(0);

  useEffect(() => {
    setAvgCost(calculateAverageAppCost(costs, APP_ID));
  }, [costs]);

  const [loading, setLoading] = useState(false);
  const [orgCount, setOrgCount] = useState('-');
  const [userCount, setUserCount] = useState('-');
  const [activeConnections, setActiveConnections] = useState('-');

  const fetchData = useCallback(async () => {
    setLoading(true);
    let data = {};
    try {
      data = await new WebAPIClient().GET('/kpis/home');
    } catch (err) {
      setLoading(false);
    }
    setOrgCount(data.org_count || '-');
    setUserCount(data.user_count || '-');
    setActiveConnections(data.active_connections || '-');
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Grid size={{ xs: 12 }} sx={{ maxWidth: '100vw' }}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        sx={{ pt: 1 }}>
        <IconImg iconName='energytracer' height={45} label='EnergyTracer' />
        <Typography variant='h4' ml={2}>
          EnergyTracer
        </Typography>
      </Box>
      <SwipeableContainer>
        <DataCard
          loading={loading}
          icon='router'
          value={activeConnections}
          label='Active Connections'
          color={theme.palette.veregy_colors.grey}
        />
        <DataCard
          loading={loading}
          icon='buildings'
          value={orgCount}
          label='Total Organizations'
          color={theme.palette.veregy_colors.orange}
        />
        <DataCard
          loading={loading}
          icon='users'
          value={userCount}
          label='Monthly Active Users'
          color={theme.palette.veregy_colors.blue}
        />
        <DataCard
          loading={costsLoading}
          icon='dollar-sign'
          value={numberToCurrency2.format(avgCost)}
          label='Avg Daily Cost'
          color={theme.palette.veregy_colors.green}
        />
      </SwipeableContainer>
    </Grid>
  );
}
