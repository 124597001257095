import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';

import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SingleBar from './SingleBar';

function GroupContainer(props) {
  const { metrics, metricCounts } = props;
  const theme = useTheme();
  const [max, setMax] = useState(0);

  useEffect(() => {
    if (!metricCounts) return;
    const selectedValues = metrics.map((metric) =>
      get(metricCounts, metric.metric, 0)
    );
    setMax(Math.max(...selectedValues));
  }, [metrics, metricCounts]);

  return (
    <Box sx={{ mb: 0.5 }}>
      {map(metrics, (metric, idx) => {
        return (
          <Box key={metric.metric}>
            <Typography color='textSecondary' variant='body1'>
              {metric.label}
            </Typography>
            <SingleBar
              value={get(metricCounts, metric.metric, 0)}
              max={max}
              color={theme.palette.veregy_colors[metric.color]}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default GroupContainer;
