import React from 'react';
import { useDispatch } from 'react-redux';

import { showLoading, hideLoading } from '../../../../store/app';
import WebAPIClient, {
  errorResponseToastr,
} from '../../../../api/_energytracer';
import EnergyTracerKPIsPage from '../../../../components/KPIsPage/EnergyTracerKPIsPage';

export default function KPIs() {
  const dispatch = useDispatch();

  const energytracerKpiMetrics = {
    users: [
      { label: 'Users', metric: 'all_users', color: 'blue' },
      {
        label: 'Monthly Active Users',
        metric: 'monthly_users',
        color: 'orange',
      },
      {
        label: 'Daily Active Users',
        metric: 'daily_users',
        color: 'green',
      },
    ],
    resources: [
      { label: 'Organizations', metric: 'organizations', color: 'blue' },
      {
        label: 'Buildings',
        metric: 'buildings',
        color: 'orange',
      },
      {
        label: 'Utility Accounts',
        metric: 'accounts',
        color: 'red',
      },
    ],
    bills: [
      { label: 'Plug Bills', metric: 'all_plug_bills', color: 'blue' },
      {
        label: 'Monthly Plug Bills',
        metric: 'monthly_plug_bills',
        color: 'orange',
      },
      {
        label: 'Yearly Plug Bills',
        metric: 'yearly_plug_bills',
        color: 'red',
      },
      { label: 'Manual Bills', metric: 'all_bills', color: 'blue' },
      {
        label: 'Monthly Manual Bills',
        metric: 'monthly_bills',
        color: 'orange',
      },
      {
        label: 'Yearly Manual Bills',
        metric: 'yearly_bills',
        color: 'red',
      },
    ],
  };

  const fetchData = async () => {
    let data = {};
    dispatch(showLoading());
    try {
      data = await new WebAPIClient().GET('/kpis/dashboard');
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
    return data;
  };

  return (
    <EnergyTracerKPIsPage
      metrics={energytracerKpiMetrics}
      fetchData={fetchData}
      with_trend={false}
    />
  );
}
