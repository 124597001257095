import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid2';

import UsersTable from './UsersTable';
import { getCognitoUsers } from '../../../store/cognito';

export default function Users() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCognitoUsers());
  }, [dispatch]);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ mt: 1 }}>
      <Grid
        size={{ xs: 12 }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
          px: 1,
        }}>
        <UsersTable />
      </Grid>
    </Grid>
  );
}
