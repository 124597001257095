import traverse from 'traverse';

import concat from 'lodash/concat';
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';

const generatePortfolioTree = (
  portfolio,
  portfolioMemberships,
  organizations,
  sites,
  loggers,
  meters,
  inverters,
  sensors
) => {
  const childRelations = filter(portfolioMemberships, {
    portfolio_id: portfolio.org_id,
  });
  const childOrgs = [];

  each(childRelations, (relation) => {
    let organization = find(organizations, { org_id: relation.member_id });
    if (organization) childOrgs.push(organization);
  });

  return {
    type: 'tree-node-parent',
    resourceType: 'portfolio',
    label: get(portfolio, 'name'),
    itemId: get(portfolio, 'org_id'),
    hide: false,
    icon: ['fal', 'folder'],
    attr: String(childOrgs.length),
    children: childOrgs.map((org) =>
      generateOrgTree(org, sites, loggers, meters, inverters, sensors)
    ),
  };
};

const generateOrgTree = (
  organization,
  sites,
  loggers,
  meters,
  inverters,
  sensors
) => {
  let orgSites = filter(sites, { org_id: organization.org_id });
  orgSites = sortBy(orgSites, 'name');

  const children = map(orgSites, (site) =>
    generateSiteTree(site, loggers, meters, inverters, sensors)
  );
  return {
    type: 'tree-node-organization',
    label: organization.name,
    resourceType: 'organization',
    itemId: organization.org_id,
    icon: ['fal', 'buildings'],
    attr: String(orgSites.length),
    children,
  };
};

const generateSiteTree = (site, loggers, meters, inverters, sensors) => {
  const siteLoggers = filter(loggers, { site_id: site.site_id });

  return {
    type: 'tree-node-site',
    label: site.name,
    resourceType: 'site',
    itemId: site.site_id,
    icon: ['fal', 'building'],
    children: map(siteLoggers, (logger) =>
      generateLoggerTree(logger, meters, inverters, sensors)
    ),
  };
};

const generateLoggerTree = (logger, meters, inverters, sensors) => {
  const loggerMeters = filter(meters, { logger_id: logger.logger_id });

  const meterIds = map(loggerMeters, 'meter_id');
  const loggerSensors = filter(sensors, (sensor) => {
    return includes(meterIds, sensor.meter_id);
  });
  const loggerInverters = filter(inverters, (inverter) => {
    return includes(meterIds, inverter.meter_id);
  });

  const meterNodes = map(sortBy(loggerMeters, 'parent_index'), (meter) => ({
    type: 'tree-node-meter',
    label: meter.name,
    resourceType: 'meter',
    itemId: meter.meter_id,
    icon: ['fal', 'bolt'],
    children: [],
  }));
  const sensorNodes = map(sortBy(loggerSensors, 'parent_index'), (sensor) => ({
    type: 'tree-node-sensor',
    label: sensor.name,
    resourceType: 'sensor',
    itemId: sensor.sensor_id,
    icon: ['fal', 'thermometer-half'],

    children: [],
  }));
  const inverterNodes = map(
    sortBy(loggerInverters, 'parent_index'),
    (inverter) => ({
      type: 'tree-node-inverter',
      label: inverter.name,
      resourceType: 'inverter',
      itemId: inverter.inverter_id,
      icon: ['fal', 'exchange'],
      attr: inverter.ac_size ? `${inverter.ac_size} kW` : null,
      children: [],
    })
  );

  const children = concat(meterNodes, sensorNodes, inverterNodes);
  return {
    type: 'tree-node-logger',
    label: logger.name,
    resourceType: 'logger',
    itemId: logger.logger_id,
    icon: ['fal', 'broadcast-tower'],
    children: children,
  };
};

const generateNodes = (state) => {
  const {
    organizations,
    portfolioMemberships,
    sites,
    loggers,
    meters,
    inverters,
    sensors,
  } = state;

  let nodes = [];

  const portfolios = sortBy(
    filter(organizations, { is_portfolio: true }),
    'name'
  );

  if (portfolios.length > 0) {
    nodes = map(portfolios, (portfolio) =>
      generatePortfolioTree(
        portfolio,
        portfolioMemberships,
        organizations,
        sites,
        loggers,
        meters,
        inverters,
        sensors
      )
    );
  } else {
    nodes = map(organizations, (organization) =>
      generateOrgTree(organization, sites, loggers, meters, inverters, sensors)
    );
  }

  nodes = traverse(nodes).map(function (node) {
    if (this.notLeaf && !isArray(node)) {
      node.level = this.level;
      node.id = node.itemId;
      node.itemId = node.itemId + '.' + uniqueId();
    }
    if (node?.children) {
      node.children = orderBy(node.children, ['type', 'label']);
    }
    return node;
  });

  return nodes;
};

export default generateNodes;
export {
  generatePortfolioTree,
  generateOrgTree,
  generateSiteTree,
  generateLoggerTree,
};
