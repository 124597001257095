import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import remove from 'lodash/remove';

import { SOLAR_APP_ID } from '../solar';
import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { toastr } from '../../components/CustomToast';

const getCognitoUsers = createAsyncThunk(
  'cognito/getCognitoUsers',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().cognito;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }
    try {
      dispatch(showLoading());
      const users = await new WebAPIClient(SOLAR_APP_ID).GET(
        '/resource/admin/cognito_users'
      );
      return { users };
    } catch (err) {
      console.error('ERROR :: ', err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putCognitoUser = createAsyncThunk(
  'cognito/putCognitoUser',
  async (updatedUser, { dispatch, getState, requestId }) => {
    const { users: usersState } = getState().cognito;
    let users = cloneDeep(usersState);

    try {
      dispatch(showLoading());
      const user = await new WebAPIClient(SOLAR_APP_ID).PUT(
        `/resource/admin/${updatedUser.Username}`,
        { user: updatedUser, action: 'update' }
      );

      remove(users, { sub: user.sub });
      users = concat(users, user);

      return { users };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const resendConfirmationEmail = createAsyncThunk(
  'cognito/resendConfirmationEmail',
  async (user, { dispatch }) => {
    try {
      dispatch(showLoading());
      const response = await new WebAPIClient(SOLAR_APP_ID).PUT(
        `/resource/admin/${user.email}`,
        { user, action: 'resend' }
      );

      toastr.success({ title: response });
      return {};
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getCognitoUsers, putCognitoUser, resendConfirmationEmail };
