import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { showLoading, hideLoading } from '../../../store/app';
import { toastr } from '../../CustomToast';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import BaseDialog from '../BaseDialog';
import ClipboardField from '../../Buttons/ClipboardField';
import WebAPIClient from '../../../api/_energytracer';

export const defaultState = {
  organization_id: '',
  organization_name: '',
  organization_uuid: '',
  organization_reference_number: '',
};

function OrganizationLicenseDiaglog(props) {
  const { selectedOrganization, setSelectedOrganization, setLicenses } = props;
  const dispatch = useDispatch();

  const [organization, setOrganization] = useState(defaultState);

  useEffect(() => {
    setOrganization(selectedOrganization);
  }, [selectedOrganization]);

  const handleClose = () => {
    setSelectedOrganization(defaultState);
  };

  const handleChange = (e) => {
    setOrganization((organization) => ({
      ...organization,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    try {
      await new WebAPIClient().POST(
        `licenses/${organization.organization_id}/org_info`,
        { reference_number: organization.organization_reference_number }
      );
      setLicenses((prev) =>
        prev.map((data) => {
          if (data.organization_id === organization.organization_id)
            return {
              ...data,
              organization_reference_number:
                organization.organization_reference_number,
            };
          return data;
        })
      );
      dispatch(hideLoading());
      toastr.success({ title: 'Membership Updated' });
    } catch (err) {
      dispatch(hideLoading());
      toastr.error({ title: 'Request Failed' });
    }
    handleClose();
  };

  const submitText = 'Save';
  return (
    <BaseDialog
      id={`${organization.organization_id}`}
      title={`Edit Organization`}
      submitText={submitText}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      disabled={false}>
      <List disablePadding>
        <ListItem disableGutters>
          <ClipboardField
            id='organization_name'
            value={organization.organization_name}
            label='Name'
          />
        </ListItem>

        <ListItem disableGutters>
          <ClipboardField
            id='organization_id'
            value={`${organization.organization_id}`}
            label='Sequential Id'
          />
        </ListItem>

        <ListItem disableGutters>
          <ClipboardField
            id='organization_uuid'
            value={organization.organization_uuid}
            label='UUID'
          />
        </ListItem>

        <TextFieldListItem
          id='organization_reference_number'
          label='Reference Number'
          value={organization.organization_reference_number}
          onChange={handleChange}
        />
      </List>
    </BaseDialog>
  );
}

export default OrganizationLicenseDiaglog;
