import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid2';

import { deleteSolarMeter, putSolarMeter } from '../../../../../store/solar';
import { openConfirmDialog } from '../../../../../store/dialogs';
import MeterForm from '../../../../../components/Forms/solar/MeterForm';

export default function MeterPage(props) {
  const { meter: stateMeter } = props;
  const dispatch = useDispatch();
  const [meter, setMeter] = useState(stateMeter);

  const handleDelete = () => {
    dispatch(
      openConfirmDialog({
        title: 'Delete Meter',
        message: <p>Are you sure you want to delete this meter?</p>,
        onConfirm: () => dispatch(deleteSolarMeter(meter)),
      })
    );
  };

  const handleChange = (e) => {
    const id = e.target.id || e.target.name;
    const value = e.target.value;
    setMeter((prevMeter) => ({ ...prevMeter, [id]: value }));
  };

  const handleSubmit = () => {
    dispatch(putSolarMeter(meter));
  };

  return (
    <>
      <Grid
        size={{ xs: 12 }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
          px: 1,
        }}>
        <Card raised sx={{ maxWidth: 400, width: '100%', px: 2, py: 1 }}>
          <MeterForm
            mode='edit'
            meter={meter}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
          <CardActions sx={{ justifyContent: 'space-between' }}>
            <Button variant='outlined' color='error' onClick={handleDelete}>
              Delete
            </Button>
            <Button variant='contained' onClick={handleSubmit}>
              Save
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </>
  );
}
