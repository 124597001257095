import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import remove from 'lodash/remove';
import forEach from 'lodash/forEach';
import _range from 'lodash/range';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

import {
  today,
  yesterday,
  thisWeek,
  lastWeek,
  thisMonth,
  lastMonth,
  getLocalTimezone,
} from '../../helpers/date';
import WebAPIClient, { errorResponseToastr } from '../../api';
import SelectRange from '../Selectors/SelectRange';
import SelectTimezone, { TIMEZONES } from '../Selectors/SelectTimezone';
import KioskUsageChart from './Chart';
import SelectResource from './SelectResource';
import SelectIpAddress from './SelectIpAddress';
import SelectUserAgent from './SelectUserAgent';

const RANGES = [
  {
    key: 0,
    label: 'Today',
    func: today,
  },
  {
    key: 1,
    label: 'Yesterday',
    func: yesterday,
  },
  {
    key: 2,
    label: 'This Week',
    func: thisWeek,
  },
  {
    key: 3,
    label: 'Last Week',
    func: lastWeek,
  },
  {
    key: 4,
    label: 'This Month',
    func: thisMonth,
  },
  { key: 5, label: 'Last Month', func: lastMonth },
  { key: 6, label: 'Custom Range' },
];

const compileChartData = (range, data) => {
  const _data = cloneDeep(data);
  let timeseries = _range(range.start.unix(), range.end.unix() + 1, 900);
  timeseries = map(timeseries, (timestamp) => {
    let row = { timestamp };

    const resourceData = remove(_data, (d) => {
      return d.timestamp === timestamp;
    });
    forEach(resourceData, (d) => {
      row[`${d.resource_id}=${d.source_ip}=${d.user_agent}`] = d.value;
    });
    return row;
  });
  return timeseries;
};

export default function KioskUsage(props) {
  const { appId } = props;
  const [initialLoad, setInitialLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState(TIMEZONES[0]);
  const [range, setRange] = useState(RANGES[1]);
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [kioskInstances, setKioskInstances] = useState([]);

  const [resourceIds, setResourceIds] = useState([]);
  const [ipAddresses, setIpAddresses] = useState([]);
  const [userAgents, setUserAgents] = useState([]);
  const [selectedResourceId, setSelectedResourceId] = useState('');
  const [selectedIpAddress, setSelectedIpAddress] = useState('');
  const [selectedUserAgent, setSelectedUserAgent] = useState('');
  const [showUserAgentDetails, setShowUserAgentDetails] = useState(false);

  useEffect(() => {
    setTimezone(getLocalTimezone());
  }, []);

  useEffect(() => {
    if (timezone.zone) {
      setRange((r) => {
        if (r.key === 6) {
          return { ...r };
        } else {
          return { ...r, ...r.func(timezone.zone) };
        }
      });
    }
  }, [timezone.zone]);

  useEffect(() => {
    if (range && range.start && range.end && timezone && !initialLoad) {
      setInitialLoad(true);
      fetchRawData(range);
    }
    // eslint-disable-next-line
  }, [range, timezone, initialLoad]);

  useEffect(() => {
    if (rawData.length > 0) {
      let uniqResourceIds = new Set();
      let uniqIpAddresses = new Set();
      let uniqUserAgents = new Set();
      rawData.forEach((d) => {
        uniqResourceIds.add(d.resource_id);
        uniqIpAddresses.add(d.source_ip);
        uniqUserAgents.add(d.user_agent);
      });
      setResourceIds(Array.from(uniqResourceIds));
      setIpAddresses(Array.from(uniqIpAddresses));
      setUserAgents(Array.from(uniqUserAgents));
    } else {
      setResourceIds([]);
      setIpAddresses([]);
      setUserAgents([]);
    }
  }, [rawData, range, selectedResourceId]);

  useEffect(() => {
    if (rawData.length > 0) {
      let uniqKioskInstances = [];
      rawData.forEach((d) => {
        const instance = d.resource_id + '=' + d.source_ip + '=' + d.user_agent;
        if (!uniqKioskInstances.includes(instance)) {
          uniqKioskInstances.push(instance);
        }
      });
      setKioskInstances(uniqKioskInstances);
      setChartData(compileChartData(range, rawData));
    } else {
      setKioskInstances([]);
      setChartData([]);
    }
  }, [rawData, range]);

  const fetchRawData = async (range) => {
    setLoading(true);
    new WebAPIClient(appId)
      .GET('/resource/kiosk_usage', {
        start: range.start.unix(),
        end: range.end.unix(),
      })
      .then((payload) => {
        setRawData(payload);
      })
      .catch((err) => {
        errorResponseToastr(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectRange = (newRange) => {
    setRange(newRange);
    if (initialLoad) {
      fetchRawData(newRange);
    }
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='flex-start'
      spacing={1}
      sx={{ maxWidth: '100%', px: 1, mb: 6, mt: 1 }}>
      <Grid size={{ xs: 12 }}>
        <Card raised sx={{ py: 0.5 }}>
          <Stack
            direction='row'
            justifyContent='space-evenly'
            alignItems='center'
            divider={
              <Divider orientation='vertical' flexItem sx={{ my: 0.5 }} />
            }>
            <SelectResource
              appId={appId}
              resourceIds={resourceIds}
              selectedResourceId={selectedResourceId}
              setSelectedResourceId={setSelectedResourceId}
            />
            <SelectIpAddress
              ipAddresses={ipAddresses}
              selectedIpAddress={selectedIpAddress}
              setSelectedIpAddress={setSelectedIpAddress}
            />
            <SelectUserAgent
              userAgents={userAgents}
              selectedUserAgent={selectedUserAgent}
              setSelectedUserAgent={setSelectedUserAgent}
              showDetails={showUserAgentDetails}
              setShowDetails={setShowUserAgentDetails}
            />
            <SelectRange
              ranges={RANGES}
              range={range}
              defaultRange={RANGES[5]}
              setRange={handleSelectRange}
              timezone={timezone}
            />
            <SelectTimezone
              selectedTimezone={timezone}
              setSelectedTimezone={setTimezone}
            />
            <Tooltip title='fetch data' placement='top'>
              <span>
                <IconButton onClick={() => fetchRawData(range)}>
                  <FontAwesomeIcon icon={['fal', 'cloud-download']} />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </Card>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <KioskUsageChart
          appId={appId}
          range={range}
          selectedResourceId={selectedResourceId}
          selectedIpAddress={selectedIpAddress}
          selectedUserAgent={selectedUserAgent}
          kioskInstances={kioskInstances}
          chartData={chartData}
          loading={loading}
          timezone={timezone}
          userAgents={userAgents}
          showUserAgentDetails={showUserAgentDetails}
        />
      </Grid>
    </Grid>
  );
}
