import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';

import LicensesTable from './Table/LicensesTable';
import SelectOrganization from './Selectors/SelectOrganization';

function LicensesGrid(props) {
  const { appId } = props;
  const { organizations: allOrganizations } = useSelector(
    (state) => state[appId]
  );
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const _organizations = allOrganizations.filter((org) => !org.is_portfolio);
    setOrganizations(sortBy(_organizations, 'name'));
  }, [allOrganizations]);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ maxWidth: '100%', mt: 1 }}>
      <Grid
        size={{ xs: 12 }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
          mx: 1,
        }}>
        <Card raised sx={{ width: '100%', maxWidth: 400 }}>
          <SelectOrganization
            label='Select Organization'
            variant='filled'
            showAllOption
            organizations={organizations}
            orgId={selectedOrgId}
            setOrgId={(orgId) => setSelectedOrgId(orgId)}
          />
        </Card>
      </Grid>
      <Grid
        size={{ xs: 12 }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
          px: 1,
        }}>
        <LicensesTable orgId={selectedOrgId} appId={appId} />
      </Grid>
    </Grid>
  );
}

export default LicensesGrid;
