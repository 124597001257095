import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { SOLAR_APP_ID } from '.';
import { toastr } from '../../components/CustomToast';

const postSolarPortfolioMemberships = createAsyncThunk(
  'solar/postSolarPortfolioMemberships',
  async (newPortfolioMemberships, { dispatch, getState }) => {
    const statePortfolioMemberships = getState().solar.portfolioMemberships;

    try {
      dispatch(showLoading());
      const _portfolioMemberships = await new WebAPIClient(SOLAR_APP_ID).POST(
        '/resource/portfolio_memberships',
        newPortfolioMemberships
      );

      toastr.success({ title: 'Portfolio Membership created' });
      return {
        portfolioMemberships: [
          ...statePortfolioMemberships,
          ..._portfolioMemberships,
        ],
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteSolarPortfolioMembership = createAsyncThunk(
  'solar/deleteSolarPortfolioMembership',
  async (deletedPortfolioMembership, { dispatch, getState }) => {
    const statePortfolioMemberships = getState().solar.portfolioMemberships;
    let portfolioMemberships = cloneDeep(statePortfolioMemberships);
    try {
      dispatch(showLoading());
      await new WebAPIClient(SOLAR_APP_ID).DELETE(
        `/resource/portfolio_memberships/${deletedPortfolioMembership.portfolio_id}/${deletedPortfolioMembership.member_id}`
      );

      remove(portfolioMemberships, {
        portfolio_id: deletedPortfolioMembership.portfolio_id,
        member_id: deletedPortfolioMembership.member_id,
      });

      toastr.success({ title: 'Portfolio Membership deleted' });
      return { portfolioMemberships };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { postSolarPortfolioMemberships, deleteSolarPortfolioMembership };
